import React from 'react'
import { Helmet } from 'react-helmet'
import './AboutUs.scss'

function AboutUs() {
  return (
    <React.Fragment>
      <Helmet>
        <title>About Us - DriverCal</title>
        <meta name='description' content={`DriverCal About Us.`} />
        <link rel='canonical' href='https://drivercal.com/about/about-us' />
      </Helmet>
      <div className='about-us'>
        <p>
          DriverCal.com was created with one goal in mind, to support the motorsport and car culture market. By creating
          a space for users to search events easily, we hope to help event attendee numbers grow by simplifying how
          people find events. No longer do they need to search the internet and hope they stumble upon the right
          keywords. Within a few clicks, users will be able to search a list of events that interest them, and all with
          only needing to know how far they would be willing to drive to attend.
        </p>
        <p>
          For event organizers, we wanted to offer a space to promote events by not only offering free listing services,
          but also helping with banner advertisements, social media posts, special packages for sponsor support, and
          really anything else within our power. We are here to help the industry grow as a whole, and given the current
          climate, our timing couldn’t be better.
        </p>
        <p>
          A note about advertising. We DO NOT use any sort of ad sense. Any advertisements you see on this site are here
          because the companies want to be here, and they support the events you attend. Without them, many tracks and
          events would not be able to operate as they need. If you see an ad here they are friends of the industry so we
          should treat them as such.
        </p>
        <p>Welcome to DriverCal.com - Proudly Supporting Motorsports</p>
      </div>
    </React.Fragment>
  )
}

export default AboutUs
