import React, { useContext, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import Chance from 'chance'
import { AdsContext } from 'context/AdsContext'
import { AD_REACH } from 'helpers/adHelpers'
import { useResponsiveMode, Responsive } from 'hooks/useResponsive/useResponsive'
import './Leaderboard.scss'

function Leaderboard(props) {
  const [leaderboard, setLeaderboard] = useState(null)
  const { leaderboards, putAdView } = useContext(AdsContext)
  const chance = new Chance()
  const resolution = useResponsiveMode()
  const isDesktop = resolution === Responsive.desktop
  const isTablet = resolution === Responsive.tablet
  const isMobile = resolution === Responsive.mobile

  const getSource = leaderboard => {
    switch (resolution) {
      case Responsive.desktop:
        return leaderboard.desktopSource
      case Responsive.tablet:
        return leaderboard.tabletSource
      case Responsive.mobile:
        return leaderboard.tabletSource
    }
  }

  const getLeaderboard = (leaderboards, reach) => {
    if (leaderboards) {
      var filteredLeaderboards = [...leaderboards]

      if (reach) {
        filteredLeaderboards = filteredLeaderboards.filter(leaderboard => reach.includes(leaderboard.reach))
      }

      if (filteredLeaderboards && filteredLeaderboards.length > 0) {
        var weights = filteredLeaderboards.map(leaderboard => {
          return leaderboard.weight
        })

        var newLeaderboard = chance.weighted(filteredLeaderboards, weights)
        setLeaderboard(newLeaderboard)
        putAdView(newLeaderboard._id)
      }
    }
  }

  useEffect(() => {
    if (leaderboards) {
      getLeaderboard(leaderboards, props.reach)
    }
  }, [leaderboards, props.reach])

  return (
    <div
      className={`leaderboard ${isMobile ? 'mobile' : ''} ${isTablet ? 'tablet' : ''} ${isDesktop ? 'desktop' : ''}`}
    >
      {leaderboard ? (
        <a href={leaderboard.redirectUrl} target='_blank'>
          <img src={getSource(leaderboard)} alt={leaderboard.description} className='ad' />
        </a>
      ) : (
        <div className='ad placeholder' />
      )}
    </div>
  )
}

Leaderboard.propTypes = {
  reach: PropTypes.array,
}

Leaderboard.defaultProps = {
  reach: [AD_REACH.National.id],
}

export default Leaderboard
