import React from 'react'
import { getEventTypeDescription, getEventTypeColour } from 'helpers/eventTypeHelpers'
import { Link as RouterLink } from 'react-router-dom'
import moment from 'moment'
import CancelledStamp from 'images/CancelledStamp.png'
import { EventStatus } from 'helpers/statusHelpers/statusHelpers'
import './EventListItem.scss'

import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import Chip from '@material-ui/core/Chip'
import Link from '@material-ui/core/Link'
import Typography from '@material-ui/core/Typography'

import DateTimeIcon from '@material-ui/icons/Schedule'
import LocationIcon from '@material-ui/icons/Room'

function EventListItem(props) {
  var startDateTime = moment.unix(props.item.startTimestamp).utc()
  var endDateTime = moment.unix(props.item.endTimestamp).utc()

  const onRenderEventTypes = eventTypes => {
    if (eventTypes) {
      return eventTypes.map(eventType => {
        return (
          <Chip
            key={eventType}
            color='primary'
            className={`event-list-type ${getEventTypeColour(eventType)}`}
            size='small'
            label={getEventTypeDescription(eventType)}
          />
        )
      })
    }
  }

  return (
    <Link className='event-item-link' component={RouterLink} to={`/events/${props.item.slug}`} key={props.item._id}>
      <Card
        variant='outlined'
        elevation={3}
        className={`event-item ${props.selectedEventSlug === (props.item.slug || props.item._id) ? 'selected' : ''}`}
      >
        {props.item.status === EventStatus.Cancelled && (
          <div className='cancelled-stamp-container'>
            <img src={CancelledStamp} alt='Cancelled' className='cancelled-stamp' />
          </div>
        )}
        <CardContent className='event-list-date'>
          <Typography variant='h4'>{startDateTime.date()}</Typography>
          <Typography variant='subtitle1'>{startDateTime.format('MMM').toUpperCase()}</Typography>
        </CardContent>
        <CardContent>
          <Typography variant='h6'>{props.item.organizer.description}</Typography>
          <Typography variant='h5' className='event-name'>
            {props.item.eventName}
          </Typography>
          <div className='list-item-info'>
            <LocationIcon />
            <Typography variant='subtitle2'>{props.item.location.description}</Typography>
          </div>
          <div className='list-item-info'>
            <DateTimeIcon />
            <Typography variant='subtitle2'>
              {props.item.hasNoEventTime
                ? 'See event details for times'
                : `${startDateTime.format('h:mma')} - ${endDateTime.format('h:mma')}`}
            </Typography>
          </div>
          {onRenderEventTypes(props.item.eventTypes)}
        </CardContent>
      </Card>
    </Link>
  )
}

export default EventListItem
