import React, { useEffect } from 'react'
import { Route, useRouteMatch } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import Event from 'components/Event'
import EventList from 'components/EventList'
import Leaderboard from 'components/Ads/Leaderboard'
import { AD_REACH } from 'helpers/adHelpers'
import { useResponsiveMode, Responsive } from 'hooks/useResponsive/useResponsive'
import './Events.scss'

function Events(props) {
  const isDesktop = useResponsiveMode() === Responsive.desktop
  const isTablet = useResponsiveMode() === Responsive.tablet
  const isMobile = useResponsiveMode() === Responsive.mobile
  const match = useRouteMatch({
    path: '/events/:eventSlug',
    exact: false,
    strict: false,
  })

  const isEventOpen = match ? match.isExact : false

  useEffect(() => {
    if (match && match.params.eventSlug) {
      window.scrollTo(0, 0)
    }
  }, [match])

  return (
    <div
      className={`events ${isDesktop && 'desktop'} ${isTablet && 'tablet'} ${isMobile && 'mobile'} ${
        isEventOpen && 'small'
      }`}
    >
      <Helmet>
        <link rel='canonical' href='https://drivercal.com/events' />
      </Helmet>
      <Leaderboard reach={[AD_REACH.National.id, AD_REACH.Regional.id]} className='leaderboard' />
      {(!isEventOpen || isDesktop) && (
        <EventList
          className='events-list'
          location={props.location}
          ipLocation={props.ipLocation}
          selectedEventSlug={match && match.params.eventSlug}
        />
      )}
      <Route exact path={'/events/:eventSlug'}>
        <div className='details'>
          <Event />
        </div>
      </Route>
    </div>
  )
}

export default Events
