import React, { useState } from 'react'
import { useQuery } from 'react-fetching-library'
import { useParams } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import moment from 'moment'
import AddToCalendarDialog from 'components/AddToCalendarDialog'
import ErrorPage from 'components/ErrorPage'
import ReportDialog from 'components/ReportDialog'
import ShareDialog from 'components/ShareDialog'
import UpcomingEvents from './components/UpcomingEvents'
import EventAd from 'components/Ads/EventAd'
import CancelledStamp from 'images/CancelledStamp.png'
import { EventStatus } from 'helpers/statusHelpers/statusHelpers'
import { useResponsiveMode, Responsive } from 'hooks/useResponsive/useResponsive'
import './Event.scss'

import CircularProgress from '@material-ui/core/CircularProgress'
import Divider from '@material-ui/core/Divider'
import Icon from '@material-ui/core/Icon'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'

import DateTimeIcon from '@material-ui/icons/Schedule'
import DirectionsIcon from '@material-ui/icons/Directions'
import EventIcon from '@material-ui/icons/Event'
import LocationIcon from '@material-ui/icons/Room'
import MoreInfoIcon from '@material-ui/icons/Public'
import ReportIcon from '@material-ui/icons/Report'
import ShareIcon from '@material-ui/icons/Share'

import RaceMonitorIcon from 'images/raceMonitor/icon.ico'
import RaceMonitorLogo from 'images/raceMonitor/logo.png'

function Event() {
  const isMobile = useResponsiveMode() === Responsive.mobile
  const [showAddToCalendarDialog, setShowAddToCalendarDialog] = useState(false)
  const [showReportDialog, setShowReportDialog] = useState(false)
  const [showShareDialog, setShowShareDialog] = useState(false)
  let { eventSlug } = useParams()

  const fetchEvent = {
    method: 'GET',
    endpoint: `/api/events/${eventSlug}`,
  }
  const { loading, payload, error } = useQuery(fetchEvent)

  const getLocationAddress = location => {
    return `${location.streetAddress}, ${location.city}, ${location.province} ${location.postalCode}`
  }

  const getEventPageHref = eventPage => {
    if (eventPage.indexOf('http://') === 0 || eventPage.indexOf('https://') === 0) {
      return eventPage
    } else {
      return `https://${eventPage}`
    }
  }

  if (loading) {
    return (
      <div className='loading'>
        <CircularProgress />
      </div>
    )
  }

  if (error) {
    return <ErrorPage />
  }

  if (payload) {
    var startDateTime = moment.unix(payload.startTimestamp).utc()
    var endDateTime = moment.unix(payload.endTimestamp).utc()

    return (
      <>
        <Paper className={`event-paper ${isMobile && 'mobile'}`} elevation={3}>
          <Helmet>
            <title>{payload.eventName} - DriverCal</title>
            <meta name='description' content={payload.description.slice(0, 100)} />
            <link rel='canonical' href={`https://drivercal.com/events/${eventSlug}`} />
          </Helmet>
          <div className='event-header'>
            <div className='event-date'>
              <Typography variant='h4'>{startDateTime.date()}</Typography>
              <Typography variant='subtitle1'>{startDateTime.format('MMM').toUpperCase()}</Typography>
            </div>
            <div className='event-title'>
              <Typography variant={isMobile ? 'h5' : 'h4'}>{payload.eventName}</Typography>
              <Typography variant='h6'>{payload.organizer.description}</Typography>
            </div>
          </div>
          <div className='event-details'>
            <EventAd organizerId={payload.organizer._id} />
            <div className='info center'>
              <DateTimeIcon />
              {payload.hasNoEventTime ? (
                <div>
                  <Typography variant='body1'>{startDateTime.format('dddd, MMM DD')}</Typography>
                  <Typography variant='body2'>See event and registration info for times</Typography>
                </div>
              ) : (
                <Typography variant='body1'>
                  {`${startDateTime.format('dddd, MMM DD, h:mma')} - ${endDateTime.format('h:mma')}`}
                </Typography>
              )}
            </div>
            <div className='info center'>
              <LocationIcon />
              <div>
                <Typography variant='body1'>{payload.location.description}</Typography>
                <Typography variant='body2'>{getLocationAddress(payload.location)}</Typography>
              </div>
            </div>
            <div className='info'>
              {payload.raceMonitorId ? (
                <>
                  <Icon className='race-monitor-icon'>
                    <img src={RaceMonitorIcon} alt='Race Monitor' />
                  </Icon>
                  <Typography variant='body1'>
                    <a
                      className='action underlined'
                      href={getEventPageHref(payload.eventPage)}
                      target='_blank'
                      rel='noopener noreferrer'
                    >
                      CLICK HERE for live timing on Race Monitor
                    </a>
                  </Typography>
                </>
              ) : (
                <>
                  <MoreInfoIcon />
                  <Typography variant='body1'>
                    <a
                      className='action underlined'
                      href={getEventPageHref(payload.eventPage)}
                      target='_blank'
                      rel='noopener noreferrer'
                    >
                      CLICK HERE for event and registration information
                    </a>
                  </Typography>
                </>
              )}
            </div>
            {payload.description && (
              <>
                {payload.raceMonitorId ? (
                  <>
                    <div className='info race-monitor'>
                      <Typography variant={isMobile ? 'body1' : 'h6'}>{payload.description}</Typography>
                      <img src={RaceMonitorLogo} alt='Race Monitor' className='race-monitor-logo' />
                    </div>
                  </>
                ) : (
                  <div className='info'>
                    <Typography variant='body1'>{payload.description}</Typography>
                  </div>
                )}
              </>
            )}
          </div>
          <Divider />
          <div className='event-actions'>
            <a className='action' onClick={() => setShowAddToCalendarDialog(true)}>
              <EventIcon fontSize='large' />
              <Typography variant='body2'>Calendar</Typography>
            </a>
            <a
              className='action'
              href={`https://www.google.com/maps/dir/?api=1&destination=${getLocationAddress(
                payload.location
              )}&travelmode=driving`}
              target='_blank'
              rel='noopener noreferrer'
            >
              <DirectionsIcon fontSize='large' />
              <Typography variant='body2'>Directions</Typography>
            </a>
            <a className='action' onClick={() => setShowShareDialog(true)}>
              <ShareIcon fontSize='large' />
              <Typography variant='body2'>Share</Typography>
            </a>
            <a className='action' onClick={() => setShowReportDialog(true)}>
              <ReportIcon fontSize='large' />
              <Typography variant='body2'>Report</Typography>
            </a>
          </div>
          <div className='event-images'>
            {payload.status === EventStatus.Cancelled && (
              <div className='cancelled-stamp-container'>
                <img src={CancelledStamp} alt='Cancelled' className='cancelled-stamp' />
              </div>
            )}
            <iframe
              title='Location'
              width='100%'
              height='100%'
              frameBorder='0'
              style={{ border: 0 }}
              src={`https://www.google.com/maps/embed/v1/view?key=AIzaSyDP3vaYh5ZEVk8Kc9bc6jxAz7YOe47z6xc
&center=${payload.location.location.coordinates[1]},${payload.location.location.coordinates[0]}&zoom=15`} //&maptype=satellite'
            ></iframe>
          </div>
          <Divider />
          <UpcomingEvents eventId={payload._id} organizer={payload.organizer} />
        </Paper>
        <AddToCalendarDialog
          event={payload}
          showDialog={showAddToCalendarDialog}
          onClose={() => setShowAddToCalendarDialog(false)}
        />
        <ShareDialog event={payload} showDialog={showShareDialog} onClose={() => setShowShareDialog(false)} />
        <ReportDialog eventId={payload._id} showDialog={showReportDialog} onClose={() => setShowReportDialog(false)} />
      </>
    )
  }
}

export default Event
