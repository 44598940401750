import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter } from 'react-router-dom'
import { ClientContextProvider } from 'react-fetching-library'
import { ThemeProvider, createTheme } from '@material-ui/core/styles'
import App from 'components/App'
import { client } from 'api/client'
import * as serviceWorker from './serviceWorker'
import './index.scss'

const darkTheme = createTheme({
  palette: {
    // Switching the dark mode on is a single property value change.
    primary: {
      main: '#D91F2C',
    },
    secondary: {
      main: '#497F96',
    },
  },
})

ReactDOM.render(
  <ClientContextProvider client={client}>
    <ThemeProvider theme={darkTheme}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </ThemeProvider>
  </ClientContextProvider>,
  document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
