import React, { useState } from 'react'
import { Helmet } from 'react-helmet'
import * as yup from 'yup'
import { useFormik } from 'formik'
import { useMutation } from 'react-fetching-library'
import './CorporateForm.scss'

import Alert from '@material-ui/lab/Alert'
import Button from '@material-ui/core/Button'
import Checkbox from '@material-ui/core/Checkbox'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'

function CorporateForm() {
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [showSuccessAlert, setShowSuccessAlert] = useState(false)
  const [showErrorAlert, setShowErrorAlert] = useState(false)

  const submitFormAction = event => ({
    method: 'POST',
    endpoint: `/api/partnerships/corporate`,
    body: event,
  })
  const submitForm = useMutation(submitFormAction, false)

  const corporateFormSchema = yup.object().shape({
    businessName: yup.string().required('Business name is required'),
    contactName: yup.string().required('Contact name is required'),
    phoneNumber: yup
      .string()
      .test('len', 'Invalid phone number', val => {
        if (val) {
          return val.length === 10 || val.length === 11
        }
        return true
      })
      .required('Phone number is required'),
    emailAddress: yup.string().email('Invalid email address').required('Email address is required'),
  })

  const formik = useFormik({
    initialValues: {
      businessName: '',
      contactName: '',
      contactMethod: '',
      phoneNumber: '',
      emailAddress: '',
      emailSubscribe: false,
    },
    validationSchema: corporateFormSchema,
    onSubmit: values => {
      setShowSuccessAlert(false)
      setShowErrorAlert(false)
      setIsSubmitting(true)
      submitForm.mutate(values).then(result => {
        console.log('CorporateForm handleSubmit result', result)
        submitForm.reset()
        if (result.status === 204) {
          setShowSuccessAlert(true)
          formik.resetForm()
        } else {
          setShowErrorAlert(true)
        }
        setIsSubmitting(false)
      })
    },
  })

  const handleEmailSubscribeChange = value => event => {
    console.log('HERE', event, value)
    formik.handleChange({ target: { name: 'emailSubscribe', value: event.target.checked } })
  }

  return (
    <React.Fragment>
      <Helmet>
        <title>Partnership - DriverCal</title>
        <meta
          name='description'
          content={`Our goal is to support and grow motorsports. We are looking for partners who share our passion and want to work as a team to make that happen. If you would like to join one of our programs or just show that you are an ally on this mission, enter your information below and we will get right back to you!`}
        />
        <link rel='canonical' href='https://drivercal.com/partnerships' />
      </Helmet>
      <form className='corporate-form'>
        <Typography variant='subtitle1' align='center'>
          Our goal is to support and grow motorsports. We are looking for partners who share our passion and want to
          work as a team to make that happen. If you would like to join one of our programs or just show that you are an
          ally on this mission, enter your information below and we will get right back to you!
        </Typography>
        <TextField
          required
          id='businessName'
          name='businessName'
          label='Business Name'
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          disabled={isSubmitting}
          fullWidth
          margin='normal'
          variant='outlined'
          color='secondary'
          value={formik.values.businessName}
          error={formik.touched.businessName && !!formik.errors.businessName}
          helperText={formik.touched.businessName && formik.errors.businessName}
        />
        <TextField
          required
          id='contactName'
          name='contactName'
          label='Contact Name'
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          disabled={isSubmitting}
          fullWidth
          margin='normal'
          variant='outlined'
          color='secondary'
          value={formik.values.contactName}
          error={formik.touched.contactName && !!formik.errors.contactName}
          helperText={formik.touched.contactName && formik.errors.contactName}
        />
        <TextField
          required
          id='phoneNumber'
          name='phoneNumber'
          label='Phone Number'
          fullWidth
          margin='normal'
          variant='outlined'
          color='secondary'
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.phoneNumber}
          disabled={isSubmitting}
          error={formik.touched.phoneNumber && !!formik.errors.phoneNumber}
          helperText={formik.touched.phoneNumber && formik.errors.phoneNumber}
        />
        <TextField
          required
          id='emailAddress'
          name='emailAddress'
          label='Email Address'
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          disabled={isSubmitting}
          fullWidth
          margin='normal'
          variant='outlined'
          color='secondary'
          value={formik.values.emailAddress}
          error={formik.touched.emailAddress && !!formik.errors.emailAddress}
          helperText={formik.touched.emailAddress && formik.errors.emailAddress}
        />
        <FormControlLabel
          checked={formik.values.emailSubscribe}
          control={
            <Checkbox
              name='emailSubscribe'
              value={formik.values.emailSubscribe}
              onChange={handleEmailSubscribeChange()}
            />
          }
          label='Click here if you would like to keep up-to-date with our progress and future programs'
        />
        <input
          type='checkbox'
          name='ContactMeByFaxOnly'
          value='1'
          className='contact_me_by_fax_only'
          tabIndex='-1'
          autoComplete='off'
          onChange={formik.handleChange}
        />
        {showSuccessAlert && (
          <Alert severity='success' className='alert'>
            Thank you for reaching out. We will get back to you as soon as possible.
          </Alert>
        )}
        {showErrorAlert && (
          <Alert severity='error' className='alert'>
            An error occurred. Please try again or contact us directly at{' '}
            <a href='mailto:support@drivercal.com'>support@drivercal.com</a>.
          </Alert>
        )}
        <div className='actions'>
          <Button disabled={isSubmitting} variant='contained' color='secondary' onClick={formik.handleSubmit}>
            Submit
          </Button>
        </div>
      </form>
    </React.Fragment>
  )
}

export default CorporateForm
