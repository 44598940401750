import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { useResponsiveMode, Responsive } from 'hooks/useResponsive/useResponsive'
import './Footer.scss'

import Button from '@material-ui/core/Button'
import InstagramIcon from '@material-ui/icons/Instagram'
import FacebookIcon from '@material-ui/icons/Facebook'

function Footer() {
  const isMobile = useResponsiveMode() === Responsive.mobile

  return (
    <div className={`footer${isMobile ? ' mobile' : ''}`}>
      <div className='footer-column'>
        <h3>Events</h3>
        <Button component={Link} to='/events'>
          Find Events
        </Button>
        <Button component={Link} to='/create'>
          Add Events
        </Button>
      </div>
      <div className='footer-column'>
        <h3>About</h3>
        <Button component={Link} to='/about/about-us'>
          About Us
        </Button>
        <Button component={Link} to='/about/contact-us'>
          Contact Us
        </Button>
        <Button component={Link} to='/about/privacy-policy'>
          Privacy Policy
        </Button>
      </div>
      <div className='footer-column'>
        <h3>{isMobile ? 'Connect' : 'Connect with us'}</h3>
        <Button
          onClick={() => window.open('https://www.facebook.com/DriverCal/', '_blank')}
          target='_blank'
          startIcon={<FacebookIcon />}
          className={isMobile ? 'mobile' : ''}
        >
          {isMobile ? '' : 'Facebook'}
        </Button>
        {/* <Button startIcon={<InstagramIcon className={isMobile ? 'mobile' : ''} />}>
                Instagram
            </Button> */}
        {/* <Button
          onClick={() => window.open('https://www.DriverCal.shop', '_blank')}
          target='_blank'
          className={isMobile ? 'mobile' : ''}
        >
          Shop
        </Button> */}
      </div>
    </div>
  )
}

Footer.propTypes = {}

Footer.defaultProps = {}

export default Footer
