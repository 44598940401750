import React, { useState, useEffect, useContext } from 'react'
import { useHistory } from 'react-router-dom'
import { useMutation } from 'react-fetching-library'
import ErrorPage from 'components/ErrorPage'
import EventListItem from 'components/EventListItem'
import EventListFilterForm from 'components/EventListFilterForm'
import InfiniteScrollList from './components/InfiniteScrollList'
import ListSideAds from 'components/Ads/ListSideAds'
import { ListFilterContext } from 'context/listFilterContext'
import { makeStyles } from '@material-ui/core/styles'
import { useResponsiveMode, Responsive } from 'hooks/useResponsive/useResponsive'
import { constructFilterQueryString } from 'helpers/filterHelpers'
import './EventList.scss'

import Button from '@material-ui/core/Button'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import CircularProgress from '@material-ui/core/CircularProgress'
import Divider from '@material-ui/core/Divider'
import Drawer from '@material-ui/core/Drawer'
import IconButton from '@material-ui/core/IconButton'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'

import FilterIcon from '@material-ui/icons/FilterList'
import CloseIcon from '@material-ui/icons/Close'

const useStyles = makeStyles(theme => ({
  listTitle: {
    flexGrow: 1,
  },
}))

function EventList(props) {
  let history = useHistory()
  const isDesktop = useResponsiveMode() === Responsive.desktop
  const isMobile = useResponsiveMode() === Responsive.mobile
  const classes = useStyles()

  const [hasNextPage, setHasNextPage] = useState(false)
  const [skip, setSkip] = useState(0)
  const [events, setEvents] = useState([])
  const [additionalEvents, setAdditionalEvents] = useState({})

  const [isLoading, setIsLoading] = useState(true)
  const [hasErrorLoadingEvents, setHasErrorLoadingEvents] = useState(false)
  const [hasErrorFindingAddress, setHasErrorFindingAddress] = useState(false)
  const [isFilterDrawerOpen, setIsFilterDrawerOpen] = useState(false)
  const { filters, updateFilters } = useContext(ListFilterContext)

  const getEvents = () => {
    fetchEvents.mutate(filters, skip).then(result => {
      if (result.status === 200) {
        if (result.payload.events && result.payload.events.length === result.payload.pagination.limit) {
          setHasNextPage(true)
        } else {
          setHasNextPage(false)
        }
        if (result.payload.events && result.payload.events.length > 0) {
          setEvents([...events, ...result.payload.events])
        }
        if (result.payload.additionalEvents) {
          setAdditionalEvents(result.payload.additionalEvents)
        }
        setSkip(result.payload.pagination.skip + result.payload.pagination.limit)
        setIsLoading(false)
      } else if (result.status === 400) {
        setHasErrorFindingAddress(true)
        setIsLoading(false)
      } else {
        setHasErrorLoadingEvents(true)
        setIsLoading(false)
      }
    })
  }

  useEffect(() => {
    var queryString = constructFilterQueryString(filters)
    history.replace(`?${queryString}`)
    setIsLoading(true)
    setHasErrorLoadingEvents(false)
    setHasErrorFindingAddress(false)
    // TODO: check filters
    getEvents()
  }, [filters])

  const handleSubmit = listFilters => {
    if (listFilters !== filters) {
      setSkip(0)
      setEvents([])
      updateFilters(listFilters)
    } else {
      console.log('Filters did not change')
    }
    setIsFilterDrawerOpen(false)
  }

  const fetchEventsAction = (listFilters, skip) => {
    var endpoint = '/api/events?'
    var limit = 10

    endpoint = endpoint + constructFilterQueryString(listFilters)

    // Pagination
    endpoint = endpoint + `&limit=${limit}&skip=${skip}`

    return {
      method: 'GET',
      endpoint: endpoint,
    }
  }
  const fetchEvents = useMutation(fetchEventsAction)

  const onRenderListItem = item => {
    return <EventListItem key={item._id} selectedEventSlug={props.selectedEventSlug} item={item} />
  }

  const onRenderLoading = () => {
    return (
      <div className='loading'>
        <CircularProgress />
      </div>
    )
  }

  const onRenderLoadMoreButton = () => {
    return (
      <div className='load-more-items'>
        <Button onClick={getEvents} variant='contained' color='secondary'>
          Show more events
        </Button>
      </div>
    )
  }

  const onRenderErrorFindingAddress = () => {
    return (
      <Card variant='outlined' elevation={3} className='location-error'>
        <CardContent>
          <Typography variant='h6'>
            We had trouble finding your location.{' '}
            {!isDesktop ? (
              <Button color='primary' size='large' onClick={() => setIsFilterDrawerOpen(!isFilterDrawerOpen)}>
                Please check your location or switch to "Use my location".
              </Button>
            ) : (
              <>
                <br /> Please check your location or switch to "Use my location".
              </>
            )}
          </Typography>
        </CardContent>
      </Card>
    )
  }

  const onRenderNoEvents = () => {
    return (
      <Card variant='outlined' elevation={3} className='no-events'>
        <CardContent>
          <Typography variant='h6'>
            No events found matching your filters.{' '}
            {!isDesktop && (
              <Button color='primary' size='large' onClick={() => setIsFilterDrawerOpen(!isFilterDrawerOpen)}>
                Update your filters.
              </Button>
            )}
          </Typography>
        </CardContent>
      </Card>
    )
  }

  useEffect(() => {
    setEvents([...events])
  }, [props.selectedEventSlug])

  if (hasErrorLoadingEvents) {
    return <ErrorPage />
  }

  return (
    <div className={`event-list ${isMobile ? 'mobile' : ''}`}>
      {(!isDesktop || props.selectedEventSlug) && (
        <Drawer
          className='drawer'
          anchor='right'
          open={isFilterDrawerOpen}
          onClose={() => setIsFilterDrawerOpen(false)}
        >
          <div className='drawer-content'>
            <div className='drawer-header'>
              <IconButton
                onClick={() => setIsFilterDrawerOpen(!isFilterDrawerOpen)}
                className={classes.drawerCloseButton}
              >
                <CloseIcon />
              </IconButton>
            </div>
            <EventListFilterForm onSubmit={handleSubmit} initialListFilters={filters} isDisabled={isLoading} />
          </div>
        </Drawer>
      )}
      <div className='list-header'>
        <Typography variant={isMobile ? 'h4' : 'h3'} className='list-title page-header'>
          EVENTS
        </Typography>
        {(!isDesktop || props.selectedEventSlug) && (
          <div
            className='filter-container'
            onClick={() => {
              setIsFilterDrawerOpen(!isFilterDrawerOpen)
            }}
          >
            <Typography variant={isMobile ? 'h6' : 'h5'} className='filter-text'>
              Filter
            </Typography>
            <FilterIcon className='filter-icon' />
          </div>
        )}
      </div>
      <Divider className='divider' />
      {props.selectedEventSlug && <ListSideAds quantity={3} />}
      <div className={`events-container ${isDesktop && !props.selectedEventSlug && 'desktop'}`}>
        {isDesktop && !props.selectedEventSlug && (
          <div className='left-column'>
            <Paper className='filters-container' elevation={3}>
              <EventListFilterForm
                onSubmit={handleSubmit}
                initialListFilters={filters}
                showCondensedView
                isDisabled={isLoading}
              />
            </Paper>
            <ListSideAds quantity={5} />
          </div>
        )}
        {isLoading ? (
          <div className='loading'>
            <CircularProgress />
          </div>
        ) : hasErrorFindingAddress ? (
          <>{onRenderErrorFindingAddress()}</>
        ) : (
          <div className='list-container'>
            {events && events.length > 0 ? (
              <InfiniteScrollList
                items={events}
                onRenderAd={() => <ListSideAds />}
                onRenderItem={onRenderListItem}
                onRenderLoading={onRenderLoading}
                onRenderLoadMoreButton={onRenderLoadMoreButton}
                pageSize={skip}
                loadMoreItems={getEvents}
                hasNextPage={hasNextPage}
                displayAds={!isDesktop || props.selectedEventSlug}
              />
            ) : (
              <>
                {onRenderNoEvents()}
                {additionalEvents && additionalEvents.events && additionalEvents.events.length > 0 && (
                  <>
                    <Typography variant={isMobile ? 'subtitle1' : 'h5'} className='additional-events-title'>
                      {`Here are events within ${additionalEvents.distance} miles:`}
                    </Typography>
                    <InfiniteScrollList
                      items={additionalEvents.events}
                      onRenderAd={() => <ListSideAds />}
                      onRenderItem={onRenderListItem}
                      onRenderLoading={onRenderLoading}
                      pageSize={additionalEvents.events.length}
                      displayAds={!isDesktop || props.selectedEventSlug}
                    />
                  </>
                )}
              </>
            )}
          </div>
        )}
      </div>
    </div>
  )
}

export default EventList
