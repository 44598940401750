import React, { useState, useEffect } from 'react'
import AddLocationForm from 'components/AddLocationForm'
import './LocationForm.scss'

import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import IconButton from '@material-ui/core/IconButton'
import InputAdornment from '@material-ui/core/InputAdornment'
import Link from '@material-ui/core/Link'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction'
import ListItemText from '@material-ui/core/ListItemText'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'

import AddIcon from '@material-ui/icons/Add'
import CloseIcon from '@material-ui/icons/Close'
import SearchIcon from '@material-ui/icons/Search'

function LocationForm(props) {
  const [showAddNewLocation, setShowAddNewLocation] = useState(false)
  const [location, setLocation] = useState('')
  const [locationResults, setLocationResults] = useState(null)
  const [locationAnchorEl, setLocationAnchorEl] = useState(null)
  const [openNewLocationForm, setOpenNewLocationForm] = useState(false)

  useEffect(() => {
    if (props.fetchLocations.payload !== undefined) {
      var results
      if (props.fetchLocations.payload && props.fetchLocations.payload.length > 0) {
        results = props.fetchLocations.payload
      } else {
        results = []
      }
      setLocationResults(results)
    } else {
      setLocationResults(props.fetchLocations.payload)
    }
  }, [props.fetchLocations.payload])

  useEffect(() => {
    if (props.formState.location) {
      if (props.formState.location._id) {
        setLocation(props.formState.location.description)
      } else {
        setOpenNewLocationForm(true)
      }
    }
  }, [props.formState.location])

  const handleLocationSearchSelect = location => {
    setShowAddNewLocation(false)
    console.log('handleSearchSelect', location)
    props.handleChange({ target: { name: 'location', value: location } })
    props.fetchLocations.reset()
    setLocationAnchorEl(null)
    setOpenNewLocationForm(false)
  }

  const handleNewLocationFormChange = location => {
    console.log('handleNewLocationFormChange', location)
    props.handleChange({ target: { name: 'location', value: location } })
  }

  const handleLocationSearchChange = event => {
    setLocation(event.target.value)
  }

  const handleLocationSearch = event => {
    setLocationAnchorEl(event.currentTarget)
    if (event.key === 'Enter') {
      console.log('handleLocationSearch', event)
      event.preventDefault()
      props.fetchLocations.mutate(location)
    }
  }

  const handleLocationSearchClick = event => {
    if (location) {
      console.log('handleLocationSearchClick', event)
      props.fetchLocations.mutate(location)
    }
  }

  const handleLocationSearchClose = () => {
    setShowAddNewLocation(true)
    props.fetchLocations.reset()
    setLocationAnchorEl(null)
  }

  const clearLocationSearch = () => {
    setLocation('')
    props.handleChange({ target: { name: 'location', value: '' } })
  }

  const getLocationAddress = location => {
    return `${location.streetaddress}, ${location.city}, ${location.province} ${location.postalcode}`
  }

  return (
    <div className='location-form'>
      <Typography variant='h6' gutterBottom>
        Event Location
      </Typography>
      {!openNewLocationForm && (
        <Grid container>
          <Grid item xs={12}>
            <TextField
              required
              disabled={props.fetchLocations.loading}
              id='location'
              name='location'
              label='Location'
              placeholder='Location'
              value={location}
              onChange={handleLocationSearchChange}
              onKeyPress={handleLocationSearch}
              autoComplete='off'
              margin='normal'
              fullWidth
              variant='outlined'
              color='secondary'
              InputProps={{
                endAdornment: (
                  <InputAdornment position='end'>
                    {location && (
                      <IconButton onClick={clearLocationSearch}>
                        <CloseIcon />
                      </IconButton>
                    )}
                    {!props.formState.location && (
                      <IconButton onClick={handleLocationSearchClick}>
                        <SearchIcon />
                      </IconButton>
                    )}
                  </InputAdornment>
                )
              }}
            />
          </Grid>
          <Menu
            id={'location-results'}
            anchorEl={locationAnchorEl}
            open={!!locationResults}
            onClose={handleLocationSearchClose}
            getContentAnchorEl={null}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left'
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left'
            }}
            PaperProps={{
              style: {
                maxHeight: '20rem',
                minWidth: '320px'
              }
            }}
            className='organizer-results'
          >
            {locationResults && locationResults.length > 0 ? (
              locationResults.map(location => {
                return (
                  <MenuItem
                    className='search-result'
                    key={location._id}
                    onClick={() => handleLocationSearchSelect(location)}
                  >
                    <Typography variant='subtitle1'>{location.description}</Typography>
                  </MenuItem>
                )
              })
            ) : (
              <MenuItem className='no-search-result' disabled>
                No results
              </MenuItem>
            )}
          </Menu>
          {showAddNewLocation && !openNewLocationForm && (
            <p className='add-new-location'>
              Can't find it?
              <Button
                color='secondary'
                variant='contained'
                disabledElevation
                onClick={() => {
                  setOpenNewLocationForm(true)
                  handleLocationSearchClose()
                }}
                startIcon={<AddIcon />}
              >
                Add it
              </Button>
            </p>
          )}
        </Grid>
      )}
      {openNewLocationForm && (
        <React.Fragment>
          <AddLocationForm setLocation={handleNewLocationFormChange} location={props.formState.location} />
          <div className='search-again'>
            <Link
              color='secondary'
              onClick={() => {
                setOpenNewLocationForm(false)
                setShowAddNewLocation(false)
                clearLocationSearch()
              }}
            >
              Search again
            </Link>
          </div>
        </React.Fragment>
      )}
    </div>
  )
}

export default LocationForm
