import React, { useState, useEffect } from 'react'
import AddOrganizerForm from 'components/AddOrganizerForm'
import './OrganizerForm.scss'

import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import IconButton from '@material-ui/core/IconButton'
import InputAdornment from '@material-ui/core/InputAdornment'
import Link from '@material-ui/core/Link'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction'
import ListItemText from '@material-ui/core/ListItemText'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'

import AddIcon from '@material-ui/icons/Add'
import CloseIcon from '@material-ui/icons/Close'
import SearchIcon from '@material-ui/icons/Search'

function OrganizerForm(props) {
  const [showAddNewOrganizer, setShowAddNewOrganizer] = useState(false)
  const [organizer, setOrganizer] = useState('')
  const [organizerResults, setOrganizerResults] = useState(null)
  const [organizerAnchorEl, setOrganizerAnchorEl] = useState(null)
  const [openNewOrganizerForm, setOpenNewOrganizerForm] = useState(false)

  useEffect(() => {
    if (props.fetchOrganizers.payload !== undefined) {
      var results
      if (props.fetchOrganizers.payload && props.fetchOrganizers.payload.length > 0) {
        results = props.fetchOrganizers.payload
      } else {
        results = []
      }
      setOrganizerResults(results)
    } else {
      setOrganizerResults(props.fetchOrganizers.payload)
    }
  }, [props.fetchOrganizers.payload])

  useEffect(() => {
    if (props.formState.organizer) {
      if (props.formState.organizer._id) {
        setOrganizer(props.formState.organizer.description)
      } else {
        setOpenNewOrganizerForm(true)
      }
    }
  }, [props.formState.organizer])

  const handleOrganizerSearchSelect = organizer => {
    setShowAddNewOrganizer(false)
    console.log('handleSearchSelect', organizer)
    props.handleChange({ target: { name: 'organizer', value: organizer } })
    props.fetchOrganizers.reset()
    setOrganizerAnchorEl(null)
    setOpenNewOrganizerForm(false)
  }

  const handleNewOrganizerFormChange = organizer => {
    console.log('handleNewOrganizerFormChange', organizer)
    props.handleChange({ target: { name: 'organizer', value: organizer } })
  }

  const handleOrganizerSearchChange = event => {
    setOrganizer(event.target.value)
  }

  const handleOrganizerSearch = event => {
    setOrganizerAnchorEl(event.currentTarget)
    if (event.key === 'Enter') {
      console.log('handleOrganizerSearch', event)
      event.preventDefault()
      props.fetchOrganizers.mutate(organizer)
    }
  }

  const handleOrganizerSearchClick = event => {
    if (organizer) {
      console.log('handleOrganizerSearchClick', event)
      props.fetchOrganizers.mutate(organizer)
    }
  }

  const handleOrganizerSearchClose = () => {
    setShowAddNewOrganizer(true)
    props.fetchOrganizers.reset()
    setOrganizerAnchorEl(null)
  }

  const clearOrganizerSearch = () => {
    setOrganizer('')
    props.handleChange({ target: { name: 'organizer', value: '' } })
  }

  return (
    <div className='organizer-form'>
      <Typography variant='h6' gutterBottom>
        Event Organizer
      </Typography>
      {!openNewOrganizerForm && (
        <Grid container>
          <Grid item xs={12}>
            <TextField
              required
              disabled={props.fetchOrganizers.loading}
              id='organizer'
              name='organizer'
              label='Company/Club/Organizer'
              placeholder='Company/Club/Organizer'
              value={organizer}
              onChange={handleOrganizerSearchChange}
              onKeyPress={handleOrganizerSearch}
              autoComplete='off'
              margin='normal'
              fullWidth
              variant='outlined'
              color='secondary'
              InputProps={{
                endAdornment: (
                  <InputAdornment position='end'>
                    {organizer && (
                      <IconButton onClick={clearOrganizerSearch}>
                        <CloseIcon />
                      </IconButton>
                    )}
                    {!props.formState.organizer && (
                      <IconButton onClick={handleOrganizerSearchClick}>
                        <SearchIcon />
                      </IconButton>
                    )}
                  </InputAdornment>
                )
              }}
            />
          </Grid>
          <Menu
            id={'organizer-results'}
            anchorEl={organizerAnchorEl}
            open={!!organizerResults}
            onClose={handleOrganizerSearchClose}
            getContentAnchorEl={null}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left'
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left'
            }}
            PaperProps={{
              style: {
                maxHeight: '20rem',
                minWidth: '320px'
              }
            }}
            className='organizer-results'
          >
            {organizerResults && organizerResults.length > 0 ? (
              organizerResults.map(organizer => {
                return (
                  <MenuItem
                    className='search-result'
                    key={organizer._id}
                    onClick={() => handleOrganizerSearchSelect(organizer)}
                  >
                    <Typography variant='subtitle1'>{organizer.description}</Typography>
                  </MenuItem>
                )
              })
            ) : (
              <MenuItem className='no-search-result' disabled>
                No results
              </MenuItem>
            )}
          </Menu>
          {showAddNewOrganizer && !openNewOrganizerForm && (
            <p className='add-new-organizer'>
              Can't find it?
              <Button
                color='secondary'
                variant='contained'
                onClick={() => {
                  setOpenNewOrganizerForm(true)
                  handleOrganizerSearchClose()
                }}
                startIcon={<AddIcon />}
              >
                Add it
              </Button>
            </p>
          )}
        </Grid>
      )}
      {openNewOrganizerForm && (
        <React.Fragment>
          <AddOrganizerForm setOrganizer={handleNewOrganizerFormChange} organizer={props.formState.organizer} />
          <div className='search-again'>
            <Link
              color='secondary'
              onClick={() => {
                setOpenNewOrganizerForm(false)
                setShowAddNewOrganizer(false)
                clearOrganizerSearch()
              }}
            >
              Search again
            </Link>
          </div>
        </React.Fragment>
      )}
    </div>
  )
}

export default OrganizerForm
