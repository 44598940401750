import React from 'react'
import CEL from 'images/CEL.png'
import './ErrorPage.scss'

import Typography from '@material-ui/core/Typography'

function ErrorPage(props) {
  return (
    <div className='error-page'>
      <img src={CEL} alt='Check Engine Light' />
      <Typography variant='h3' className='page-header'>
        Something went wrong
      </Typography>
      <Typography variant='h5'>
        Try refreshing the page or <a href={`mailto:support@drivercal.com?subject=Something went wrong`}>email us</a>{' '}
        and let us know
      </Typography>
    </div>
  )
}

export default ErrorPage
