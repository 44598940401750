import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { useResponsiveMode, Responsive } from 'hooks/useResponsive/useResponsive'
import { Link as RouterLink } from 'react-router-dom'
import logo from 'images/header/DriverCal-Horizontal.png'
import NavBar from 'components/NavBar'
import './Header.scss'

import Button from '@material-ui/core/Button'
import Drawer from '@material-ui/core/Drawer'
import IconButton from '@material-ui/core/IconButton'

import MenuIcon from '@material-ui/icons/Menu'

function Header(props) {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false)
  let history = useHistory()
  const isMobile = useResponsiveMode() === Responsive.mobile
  const isDesktop = useResponsiveMode() === Responsive.desktop

  const navItems = () => {
    return (
      <>
        <Button color='primary' component={RouterLink} to='/events' className='events-button' size='large'>
          Events
        </Button>
        <span className='separator'></span>
        <Button color='primary' component={RouterLink} to='/create' className='create-button' size='large'>
          Add Event
        </Button>
        <span className='separator'></span>
        {/* <Button color='primary' onClick={() => onClickNavButton('/news')} className='news-button' size='large'>
          News
        </Button>
        <span className='separator'></span> */}
        <Button color='primary' component={RouterLink} to='/sponsorships' className='sponsorships-button' size='large'>
          Sponsorships
        </Button>
        <span className='separator'></span>
        <Button color='primary' component={RouterLink} to='/partnerships' className='partnerships-button' size='large'>
          Partnerships
        </Button>
        <span className='separator'></span>
        <Button color='primary' component={RouterLink} to='/about/about-us' className='about-button' size='large'>
          About
        </Button>
        <span className='separator'></span>
        {/* <Button
          color='primary'
          component={RouterLink}
          to={{ pathname: 'https://www.DriverCal.shop' }}
          target='_blank'
          className='shop-button'
          size='large'
        >
          Shop
        </Button>
        <span className='separator'></span> */}
      </>
    )
  }

  useEffect(() => {
    return history.listen(() => {
      if (isMobile) {
        setIsDrawerOpen(false)
      }
    })
  }, [history])

  return (
    <>
      {isMobile && (
        <Drawer anchor='left' className='nav-drawer' open={isDrawerOpen} onClose={() => setIsDrawerOpen(false)}>
          <div className='drawer-content'>{navItems()}</div>
        </Drawer>
      )}
      <div className={`header ${isMobile ? 'mobile' : ''}`}>
        {isMobile && (
          <IconButton color='primary' onClick={() => setIsDrawerOpen(true)}>
            <MenuIcon />
          </IconButton>
        )}
        <img
          onClick={() => history.push('/')}
          src={logo}
          className={`logo ${isMobile ? 'mobile' : ''}`}
          alt='DriverCal'
        />
        {isDesktop && (
          <img
            src={
              process.env.REACT_APP_ENVIRONMENT !== 'PROD'
                ? 'https://storage.cloud.google.com/drivercal-test.appspot.com/header/background.png'
                : 'https://storage.cloud.google.com/drivercal-217600.appspot.com/header/background.png'
            }
            className='background'
            alt=''
          />
        )}
      </div>
      {!isMobile && <NavBar navItems={navItems()} />}
    </>
  )
}

export default Header
