import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { useQuery } from 'react-fetching-library'
import SwipeableViews from 'react-swipeable-views'
import { autoPlay } from 'react-swipeable-views-utils'
import { Link as RouterLink } from 'react-router-dom'
import UpcomingEventItem from '../UpcomingEventItem'
import { useResponsiveMode, Responsive } from 'hooks/useResponsive/useResponsive'
import './UpcomingEvents.scss'

import { useTheme } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import CircularProgress from '@material-ui/core/CircularProgress'
import Link from '@material-ui/core/Link'
import MobileStepper from '@material-ui/core/MobileStepper'
import Typography from '@material-ui/core/Typography'

import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft'
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight'

const AutoPlaySwipeableViews = autoPlay(SwipeableViews)

function UpcomingEvents(props) {
  const isMobile = useResponsiveMode() === Responsive.mobile
  let history = useHistory()
  const [isLoading, setIsLoading] = useState(true)
  const [adSteps, setAdSteps] = useState([])
  const [activeStep, setActiveStep] = React.useState(0)
  const maxSteps = adSteps.length
  const theme = useTheme()

  const handleNext = () => {
    setActiveStep(prevActiveStep => prevActiveStep + 1)
  }

  const handleBack = () => {
    setActiveStep(prevActiveStep => prevActiveStep - 1)
  }

  const handleStepChange = step => {
    setActiveStep(step)
  }

  const fetchEventsAction = {
    method: 'GET',
    endpoint: `/api/events/organizer/${props.organizer._id}?limit=10&skip=0`,
  }
  const fetchEvents = useQuery(fetchEventsAction)

  useEffect(() => {
    if (fetchEvents.payload) {
      setAdSteps(fetchEvents.payload.filter(x => x._id !== props.eventId))
      setIsLoading(false)
    }
  }, [fetchEvents.payload])

  return (
    <div className={`upcoming-events ${isMobile && 'mobile'}`}>
      <Typography variant='h5' className='title'>
        {`Upcoming ${props.organizer.description} events:`}
      </Typography>
      {isLoading ? (
        <div className='loading'>
          <CircularProgress />
        </div>
      ) : (
        <React.Fragment>
          {adSteps.length > 0 ? (
            <React.Fragment>
              <AutoPlaySwipeableViews
                axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                index={activeStep}
                onChangeIndex={handleStepChange}
              >
                {adSteps.map((step, index) => (
                  <UpcomingEventItem item={step} />
                ))}
              </AutoPlaySwipeableViews>
              {adSteps && (
                <MobileStepper
                  steps={maxSteps}
                  position='static'
                  variant='dots'
                  activeStep={activeStep}
                  nextButton={
                    <Button size='small' onClick={handleNext} disabled={activeStep === maxSteps - 1}>
                      Next
                      {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
                    </Button>
                  }
                  backButton={
                    <Button size='small' onClick={handleBack} disabled={activeStep === 0}>
                      {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
                      Back
                    </Button>
                  }
                />
              )}
            </React.Fragment>
          ) : (
            <Card variant='outlined' elevation={3} className='no-events'>
              <CardContent>
                <Typography component='h1' variant='subtitle1'>
                  We don't know about any other upcoming {props.organizer.description} events. If you do,{' '}
                  <Link component={RouterLink} to='/create'>
                    add them
                  </Link>{' '}
                  or{' '}
                  <Link component={RouterLink} to='/about/contact-us'>
                    let us know
                  </Link>{' '}
                  and we will.
                </Typography>
              </CardContent>
            </Card>
          )}
        </React.Fragment>
      )}
    </div>
  )
}

export default UpcomingEvents
