export const AdStatus = {
  Inactive: 0,
  Active: 1,
}

export const getAdStatusDescription = status => {
  switch (status) {
    case 0:
      return 'Inactive'
    case 1:
      return 'Active'
    default:
      return 'Unknown'
  }
}

export const EventStatus = {
  Inactive: -1,
  Pending: 0,
  Active: 1,
  Cancelled: 2,
}

export const getEventStatusDescription = status => {
  switch (status) {
    case -1:
      return 'Inactive'
    case 0:
      return 'Pending'
    case 1:
      return 'Active'
    case 2:
      return 'Cancelled'
    default:
      return 'Unknown'
  }
}

export const LocationStatus = {
  Inactive: -1,
  Pending: 0,
  Unverified: 1,
  Verified: 2,
}

export const getLocationStatusDescription = status => {
  switch (status) {
    case -1:
      return 'Inactive'
    case 0:
      return 'Pending'
    case 1:
      return 'Unverified'
    case 2:
      return 'Verified'
    default:
      return 'Unknown'
  }
}

export const OrganizerStatus = {
  Inactive: -1,
  Pending: 0,
  Unverified: 1,
  Verified: 2,
}

export const getOrganizerStatusDescription = status => {
  switch (status) {
    case -1:
      return 'Inactive'
    case 0:
      return 'Pending'
    case 1:
      return 'Unverified'
    case 2:
      return 'Verified'
    default:
      return 'Unknown'
  }
}
