import React, { useContext, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import Chance from 'chance'
import { AdsContext } from 'context/AdsContext'
import { AD_REACH } from 'helpers/adHelpers'
import { useResponsiveMode, Responsive } from 'hooks/useResponsive/useResponsive'
import './EventAd.scss'

function EventAd(props) {
  const [eventAd, setEventAd] = useState(null)
  const { eventAds, putAdView } = useContext(AdsContext)
  const resolution = useResponsiveMode()
  const chance = new Chance()
  const isDesktop = resolution === Responsive.desktop
  const isTablet = resolution === Responsive.tablet
  const isMobile = resolution === Responsive.mobile

  const getSource = ad => {
    switch (resolution) {
      case Responsive.desktop:
        return ad.desktopSource
      case Responsive.tablet:
        return ad.desktopSource
      case Responsive.mobile:
        return ad.mobileSource
    }
  }

  const getEventAd = (eventAds, organizerId) => {
    if (eventAds) {
      var availableEventAds = [...eventAds]
      availableEventAds.filter(availableEventAd => availableEventAd.organizerId === organizerId)
      if (availableEventAds && availableEventAds.length > 0) {
        var weights = availableEventAds.map(eventAd => {
          return eventAd.weight
        })
        var newEventAd = chance.weighted(availableEventAds, weights)
        setEventAd(newEventAd)
        putAdView(newEventAd._id)
      }
    }
  }

  useEffect(() => {
    if (eventAds && props.organizerId) {
      getEventAd(eventAds, props.organizerId)
    } else {
      setEventAd(null)
    }
  }, [eventAds, props.organizerId])

  return (
    <>
      {eventAd && (
        <div
          className={`event-ad ${isMobile ? 'mobile' : ''} ${isTablet ? 'tablet' : ''} ${isDesktop ? 'desktop' : ''}`}
        >
          <a href={eventAd.redirectUrl} target='_blank'>
            <img src={getSource(eventAd)} alt={eventAd.description} className='ad' />
          </a>
        </div>
      )}
    </>
  )
}

EventAd.propTypes = {
  organizerId: PropTypes.string,
  reach: PropTypes.array,
}

EventAd.defaultProps = {
  reach: [AD_REACH.Organizer.id],
}

export default EventAd
