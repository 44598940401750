import React from 'react'
import { eventTypes } from 'helpers/eventTypeHelpers'

import Checkbox from '@material-ui/core/Checkbox'
import FormControl from '@material-ui/core/FormControl'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import FormGroup from '@material-ui/core/FormGroup'
import FormLabel from '@material-ui/core/FormLabel'
import Grid from '@material-ui/core/Grid'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'

function BasicDetailsForm(props) {
  const handleEventTypeChange = value => event => {
    if (event.target.checked) {
      props.handleChange({ target: { name: 'eventTypes', value: [...props.formState.eventTypes, value] } })
    } else {
      if (props.formState.eventTypes.some(eventType => eventType.id === value.id)) {
        const eventTypes = props.formState.eventTypes
        eventTypes.splice(eventTypes.findIndex(eventType => eventType.id === value.id), 1)
        props.handleChange({ target: { name: 'eventTypes', value: eventTypes } })
      }
    }
  }

  const renderEventTypes = () => {
    return eventTypes.map(eventType => {
      return (
        <Grid item xs={6}>
          <FormControlLabel
            checked={props.formState.eventTypes.includes(eventType)}
            control={<Checkbox name='eventType' value={eventType} onChange={handleEventTypeChange(eventType)} />}
            label={eventType.description}
          />
        </Grid>
      )
    })
  }

  return (
    <React.Fragment>
      <Typography variant='h6' gutterBottom>
        Basic Details
      </Typography>
      <Grid container>
        <Grid item xs={12}>
          <TextField
            required
            id='event-name'
            name='eventName'
            label='Event name'
            placeholder='Event name'
            value={props.formState.eventName}
            onChange={props.handleChange}
            margin='normal'
            fullWidth
            variant='outlined'
            color='secondary'
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            required
            id='description'
            name='description'
            label='Description'
            placeholder='Tell us about the event (cost, schedule, etc)...'
            value={props.formState.description}
            onChange={props.handleChange}
            multiline
            margin='normal'
            fullWidth
            rows='4'
            variant='outlined'
            color='secondary'
          />
        </Grid>
        <Grid item xs={12}>
          <FormControl component='fieldset' margin='normal'>
            <FormLabel component='legend' required margin='normal' color='secondary'>
              Event Type(s)
            </FormLabel>
            <FormGroup>
              <Grid container>{renderEventTypes()}</Grid>
            </FormGroup>
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <TextField
            required
            id='event-page'
            name='eventPage'
            label='Event page'
            placeholder='www.website.com'
            helperText='A website for people to get more details about the event and check for updates (organizer website, organizer Facebook page, etc)'
            value={props.formState.eventPage}
            onChange={props.handleChange}
            margin='normal'
            fullWidth
            variant='outlined'
            color='secondary'
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            required
            id='submitted-by'
            name='submittedBy'
            label='Your email'
            placeholder='email@email.com'
            helperText='This will not be displayed on the event page. We wll only use it to contact you with questions about your event.'
            value={props.formState.submittedBy}
            onChange={props.handleChange}
            margin='normal'
            fullWidth
            variant='outlined'
            color='secondary'
          />
        </Grid>
      </Grid>
    </React.Fragment>
  )
}

export default BasicDetailsForm
