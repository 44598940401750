import React, { useState, useEffect } from 'react'
import { List } from 'office-ui-fabric-react/lib-commonjs/List'

function InfiniteScrollList(props) {
  const [items, setItems] = useState([])
  // const onPageAdded = pageProps => {
  //   if (pageProps.startIndex + props.pageSize >= props.items.length) {
  //     props.loadMoreItems()
  //   }
  // }

  const onRenderItem = (item, index) => {
    if (item) {
      if (item.isAd) {
        return props.onRenderAd()
      }
      if (item.isLoadMoreButton) {
        return props.onRenderLoadMoreButton()
      }
      return props.onRenderItem(item, index)
    } else {
      return props.onRenderLoading()
    }
  }

  useEffect(() => {
    let newItems = [...props.items]
    if (props.displayAds) {
      var adFrequency = 3
      var numberOfGroups = Math.floor(props.items.length / adFrequency)
      if (numberOfGroups > 0) {
        for (var i = 0; i < numberOfGroups; i++) {
          newItems.splice(i * adFrequency + adFrequency + i, 0, { isAd: true })
        }
      } else {
        newItems.splice(newItems.length, 0, { isAd: true })
      }
    }
    if (props.onRenderLoading && props.hasNextPage) {
      newItems.push({ isLoadMoreButton: true })
    }
    setItems(newItems)
  }, [props.items, props.displayAds, props.hasNextPage])

  return (
    <List
      renderCount={items.length}
      items={items}
      onRenderCell={onRenderItem}
      // onPageAdded={props.hasNextPage && onPageAdded}
      renderedWindowsAhead={1}
    />
  )
}

InfiniteScrollList.defaultProps = {
  hasNextPage: false,
  onRenderLoading: null,
}

export default InfiniteScrollList
