import React, { createContext, useContext, useState, useEffect } from 'react'
import { useMutation, useParameterizedQuery } from 'react-fetching-library'
import { useRouteMatch } from 'react-router-dom'
import { ListFilterContext } from 'context/listFilterContext'
import { AD_REACH } from 'helpers/adHelpers'
import { constructFilterQueryString } from 'helpers/filterHelpers'

export const AdsContext = createContext()

const AdsProvider = props => {
  const { filters } = useContext(ListFilterContext)
  const [eventSlug, setEventSlug] = useState(null)

  const [nationalLeaderboards, setNationalLeaderboards] = useState(null)
  const [filteredListLeaderboards, setFilteredListLeaderboards] = useState(null)
  const [eventsLeaderboards, setEventsLeaderboards] = useState({})
  const [leaderboards, setLeaderboards] = useState(null)

  const [nationalListSideAds, setNationalListSideAds] = useState(null)
  const [listSideAds, setListSideAds] = useState(null)

  const [allEventAds, setAllEventAds] = useState({})
  const [eventAds, setEventAds] = useState(null)

  const match = useRouteMatch({
    path: '/events/:eventSlug',
    exact: false,
    strict: false,
  })

  useEffect(() => {
    if (match && match.params.eventSlug) {
      setEventSlug(match.params.eventSlug)
    } else {
      setEventSlug(null)
    }
  }, [match])

  const constructEventsLeaderboardQueryString = eventSlug => {
    var endpoint = `eventSlug=${eventSlug}`

    if (nationalLeaderboards) {
      endpoint = endpoint + '&includeNationalAds=false'
    } else {
      endpoint = endpoint + '&includeNationalAds=true'
    }

    return endpoint
  }

  const constructFilteredListLeaderboardQueryString = filters => {
    var endpoint = constructFilterQueryString(filters)

    if (nationalLeaderboards) {
      endpoint = endpoint + '&includeNationalAds=false'
    } else {
      endpoint = endpoint + '&includeNationalAds=true'
    }

    return endpoint
  }

  const constructFilteredListQueryString = filters => {
    var endpoint = constructFilterQueryString(filters)

    if (nationalListSideAds) {
      endpoint = endpoint + '&includeNationalAds=false'
    } else {
      endpoint = endpoint + '&includeNationalAds=true'
    }

    return endpoint
  }

  // Leaderboard Ads
  const fetchEventsLeaderboardAdsAction = eventSlug => ({
    method: 'GET',
    endpoint: `/api/ads/leaderboard?${constructEventsLeaderboardQueryString(eventSlug)}`,
  })
  const fetchEventsLeaderboardAds = useParameterizedQuery(fetchEventsLeaderboardAdsAction, false)

  useEffect(() => {
    setLeaderboards(null)
    if (eventSlug) {
      if (eventSlug in eventsLeaderboards) {
        setLeaderboards([...eventsLeaderboards[eventSlug], ...nationalLeaderboards])
      } else {
        fetchEventsLeaderboardAds.reset()
        fetchEventsLeaderboardAds.query(eventSlug)
      }
    } else {
      if (filteredListLeaderboards) {
        setLeaderboards([...nationalLeaderboards, ...filteredListLeaderboards])
      } else {
        fetchFilteredListLeaderboardAds.reset()
        fetchFilteredListLeaderboardAds.query(filters)
      }
    }
  }, [eventSlug])

  useEffect(() => {
    if (fetchEventsLeaderboardAds.payload && typeof fetchEventsLeaderboardAds.payload === 'object') {
      var nationalAds = fetchEventsLeaderboardAds.payload.filter(
        leaderboard => leaderboard.reach === AD_REACH.National.id
      )

      if (nationalAds.length > 0) {
        setNationalLeaderboards(nationalAds)
        setEventsLeaderboards({
          ...eventsLeaderboards,
          [eventSlug]: fetchEventsLeaderboardAds.payload.filter(
            leaderboard => leaderboard.reach === AD_REACH.Regional.id
          ),
        })
        setLeaderboards(fetchEventsLeaderboardAds.payload)
      } else {
        setEventsLeaderboards({ ...eventsLeaderboards, [eventSlug]: fetchEventsLeaderboardAds.payload })
        setLeaderboards([...fetchEventsLeaderboardAds.payload, ...nationalLeaderboards])
      }
    }
  }, [fetchEventsLeaderboardAds.payload])

  const fetchFilteredListLeaderboardAdsAction = filters => ({
    method: 'GET',
    endpoint: `/api/ads/leaderboard?${constructFilteredListLeaderboardQueryString(filters)}`,
  })
  const fetchFilteredListLeaderboardAds = useParameterizedQuery(fetchFilteredListLeaderboardAdsAction, false)

  useEffect(() => {
    if (!eventSlug) {
      setLeaderboards(null)
    }
    fetchFilteredListLeaderboardAds.reset()
    fetchFilteredListLeaderboardAds.query(filters)
  }, [filters])

  useEffect(() => {
    if (fetchFilteredListLeaderboardAds.payload && typeof fetchFilteredListLeaderboardAds.payload === 'object') {
      var nationalAds = fetchFilteredListLeaderboardAds.payload.filter(
        leaderboard => leaderboard.reach === AD_REACH.National.id
      )

      if (nationalAds.length > 0) {
        setNationalLeaderboards(nationalAds)
        setFilteredListLeaderboards(
          fetchFilteredListLeaderboardAds.payload.filter(leaderboard => leaderboard.reach === AD_REACH.Regional.id)
        )
        if (!eventSlug) {
          setLeaderboards(fetchFilteredListLeaderboardAds.payload)
        }
      } else {
        setFilteredListLeaderboards(fetchFilteredListLeaderboardAds.payload)
        if (!eventSlug) {
          if (fetchFilteredListLeaderboardAds.payload) {
            setLeaderboards([...fetchFilteredListLeaderboardAds.payload, ...nationalLeaderboards])
          } else if (nationalLeaderboards) {
            setLeaderboards(nationalLeaderboards)
          }
        }
      }
    } else if (nationalLeaderboards) {
      setLeaderboards(nationalLeaderboards)
    }
  }, [fetchFilteredListLeaderboardAds.payload])

  // List Side Ads
  const fetchFilteredListSideAdsAction = filters => ({
    method: 'GET',
    endpoint: `/api/ads/listSide?${constructFilteredListQueryString(filters)}`,
  })
  const fetchFilteredListSideAds = useParameterizedQuery(fetchFilteredListSideAdsAction, false)

  useEffect(() => {
    if (filters) {
      fetchFilteredListSideAds.reset()
      fetchFilteredListSideAds.query(filters)
    }
  }, [filters])

  useEffect(() => {
    if (fetchFilteredListSideAds.payload && typeof fetchFilteredListSideAds.payload === 'object') {
      var nationalAds = fetchFilteredListSideAds.payload.filter(ad => ad.reach === AD_REACH.National.id)

      if (nationalAds.length > 0) {
        setNationalListSideAds(nationalAds)
        setListSideAds(fetchFilteredListSideAds.payload)
      } else {
        setListSideAds([...fetchFilteredListSideAds.payload, ...nationalListSideAds])
      }
    }
  }, [fetchFilteredListSideAds.payload])

  // Event Ads
  const fetchEventAdsAction = eventSlug => ({
    method: 'GET',
    endpoint: `/api/ads/event?eventSlug=${eventSlug}`,
  })
  const fetchEventAds = useParameterizedQuery(fetchEventAdsAction, false)

  useEffect(() => {
    setEventAds(null)
    if (eventSlug) {
      if (eventSlug in allEventAds) {
        setEventAds(allEventAds[eventSlug])
      } else {
        fetchEventAds.reset()
        fetchEventAds.query(eventSlug)
      }
    }
  }, [eventSlug])

  useEffect(() => {
    if (fetchEventAds.payload && typeof fetchEventAds.payload === 'object') {
      setAllEventAds({ ...allEventAds, [eventSlug]: fetchEventAds.payload })
      setEventAds(fetchEventAds.payload)
    }
  }, [fetchEventAds.payload])

  // Ad View
  const putAdViewAction = adId => {
    return {
      method: 'PUT',
      endpoint: `/api/ads/${adId}/view`,
    }
  }
  const putAdView = useMutation(putAdViewAction)

  return (
    <AdsContext.Provider value={{ leaderboards, listSideAds, eventAds, putAdView: putAdView.mutate }}>
      {props.children}
    </AdsContext.Provider>
  )
}

export default AdsProvider
