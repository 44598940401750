import React from 'react'
import { Helmet } from 'react-helmet'
import './PrivacyPolicy.scss'

function PrivacyPolicy() {
  return (
    <React.Fragment>
      <Helmet>
        <title>Privacy Policy - DriverCal</title>
        <meta name='description' content={`DriverCal Privacy Policy.`} />
        <link rel='canonical' href='https://drivercal.com/about/privacy-policy' />
      </Helmet>
      <div className='privacy-policy'>
        <h3>DriverCal Privacy Policy</h3>
        <p>
          This Privacy Policy describes how your personal information is collected, used, and shared when you visit{' '}
          <a href='https://www.drivercal.com'>https://www.drivercal.com</a> (the “Site”).
        </p>

        <h5>PERSONAL INFORMATION WE COLLECT</h5>
        <p>
          When you visit the Site, we automatically collect certain information about your device, including information
          about your web browser, IP address, time zone, and some of the cookies that are installed on your device.
          Additionally, as you browse the Site, we collect information about the individual web pages that you view,
          what websites or search terms referred you to the Site, and information about how you interact with the Site.
          We refer to this automatically-collected information as “Device Information.”
        </p>
        <p>We collect Device Information using the following technologies:</p>
        <ul>
          <li>
            "Cookies” are data files that are placed on your device or computer and often include an anonymous unique
            identifier. For more information about cookies, and how to disable cookies, visit{' '}
            <a href='http://www.allaboutcookies.org'>http://www.allaboutcookies.org</a>.
          </li>
          <li>
            “Log files” track actions occurring on the Site, and collect data including your IP address, browser type,
            Internet service provider, referring/exit pages, and date/time stamps.
          </li>
          <li>“Tags” are small code elements that are used to record information about how you browse the Site.</li>
        </ul>
        <p>
          Additionally when you add an event through the Site, we collect certain information from you, including your
          email address. We refer to this information as “Event Information.”
        </p>
        <p>
          When we talk about “Personal Information” in this Privacy Policy, we are talking both about Device Information
          and Event Information.
        </p>

        <h5>HOW DO WE USE YOUR PERSONAL INFORMATION?</h5>
        <p>We use the Event Information that we collect to communicate with you about the posted event.</p>
        <p>
          We use the Device Information that we collect to help us screen for potential risk and fraud (in particular,
          your IP address), and more generally to improve and optimize our Site (for example, by generating analytics
          about how our users browse and interact with the Site, and to assess the success of our marketing and
          advertising campaigns).
        </p>

        <h5>SHARING YOUR PERSONAL INFORMATION</h5>
        <p>
          We share your Personal Information with third parties to help us use your Personal Information, as described
          above. For example, we use Google Analytics and Google Tag Manager to help us understand how our customers use
          the Site--you can read more about how Google uses your Personal Information here:{' '}
          <a href='https://www.google.com/intl/en/policies/privacy/'>
            https://www.google.com/intl/en/policies/privacy/
          </a>
          . You can also opt-out of Google Analytics here:{' '}
          <a href='https://tools.google.com/dlpage/gaoptout'>https://tools.google.com/dlpage/gaoptout</a>. You can find
          the Google Tag Manager terms of use here:{' '}
          <a href='https://www.google.com/analytics/tag-manager/use-policy/'>
            https://www.google.com/analytics/tag-manager/use-policy/
          </a>
          .
        </p>
        <p>
          Finally, we may also share your Personal Information to comply with applicable laws and regulations, to
          respond to a subpoena, search warrant or other lawful request for information we receive, or to otherwise
          protect our rights.
        </p>
        <p>
          Additionally, you can opt out of some of these services by visiting the Digital Advertising Alliance’s opt-out
          portal at: <a href='http://optout.aboutads.info/'>http://optout.aboutads.info/</a>.
        </p>

        <h5>DO NOT TRACK</h5>
        <p>
          Please note that we do not alter our Site’s data collection and use practices when we see a Do Not Track
          signal from your browser.
        </p>

        <h5>DATA RETENTION</h5>
        <p>
          When you enter an event through the Site, we will maintain your Event Information for our records unless and
          until you ask us to delete this information.
        </p>

        <h5>CHANGES</h5>
        <p>
          We may update this privacy policy from time to time in order to reflect, for example, changes to our practices
          or for other operational, legal or regulatory reasons.
        </p>

        <h5>CONTACT US</h5>
        <p>
          For more information about our privacy practices, if you have questions, or if you would like to make a
          complaint, please contact us by e-mail at <a href='mailto:support@drivercal.com'>support@drivercal.com</a>.
        </p>

        <h6>Last updated 03/07/2021</h6>
      </div>
    </React.Fragment>
  )
}

export default PrivacyPolicy
