import React, { useEffect, useState } from 'react'
import { Route, Switch, Redirect } from 'react-router'
import { Helmet } from 'react-helmet'
import Header from 'components/Header'
import Events from 'components/Events'
import CreateEvent from 'components/CreateEvent'
import AdsProvider from 'context/AdsContext'
import About from 'components/About'
import Footer from 'components/Footer'
import Partnerships from 'components/Partnerships'
import Sponsorships from 'components/Sponsorships'
import ListFilterProvider from 'context/listFilterContext'
import { useResponsiveMode, Responsive } from 'hooks/useResponsive/useResponsive'
import { useTracking } from 'hooks/useTracking/useTracking'
import 'semantic-ui-css/semantic.min.css'
import './App.scss'

import Alert from '@material-ui/lab/Alert'
import CircularProgress from '@material-ui/core/CircularProgress'
import IconButton from '@material-ui/core/IconButton'
import Snackbar from '@material-ui/core/Snackbar'

import CloseIcon from '@material-ui/icons/Close'

function App() {
  const [isLoading, setIsLoading] = useState(true)
  const [location, setLocation] = useState(null)
  const [ipLocation, setIpLocation] = useState(null)
  const [showCovidDisclaimer, setShowCovidDisclaimer] = useState(false)
  const isMobile = useResponsiveMode() === Responsive.mobile
  useTracking('UA-167898624-1')

  useEffect(() => {
    if (navigator.permissions) {
      navigator.permissions
        .query({
          name: 'geolocation',
        })
        .then(permission => {
          if (permission.state === 'granted') {
            navigator.geolocation.getCurrentPosition(position => {
              setLocation(position.coords)
              setIsLoading(false)
            })
          } else {
            getIPLocation()
          }
        })
    } else {
      getIPLocation()
    }
  }, [])

  const getIPLocation = () => {
    fetch('https://geolocation-db.com/json/0f761a30-fe14-11e9-b59f-e53803842572')
      .then(response => {
        if (response.status === 200) {
          response.json().then(data => {
            setIpLocation(data)
            setIsLoading(false)
          })
        } else {
          console.log('Failed to get IP location')
          setIsLoading(false)
        }
      })
      .catch(() => {
        console.log('Failed to get IP location')
        setIsLoading(false)
      })
  }

  if (isLoading) {
    return (
      <div className='loading'>
        <CircularProgress />
      </div>
    )
  } else {
    return (
      // <NewsProvider>
      <ListFilterProvider location={location} ipLocation={ipLocation}>
        <AdsProvider>
          <div className={`App ${isMobile ? 'mobile' : ''}`}>
            <Helmet>
              <title>Drivercal</title>
              <meta
                name='description'
                content={`Motorsport event listings from all over North America. Search by event type and location to find races and shows in your area. Are you a promoter? List your event in just minutes.`}
              />
              <link rel='canonical' href='https://drivercal.com' />
              {process.env.REACT_APP_ENVIRONMENT !== 'PROD' && <meta name='robots' content='noindex' />}
            </Helmet>
            <Header />
            <div className='workspace'>
              <Switch>
                <Route exact path='/'>
                  <Redirect to='/events' />
                </Route>
                <Route path={['/events', '/events/:eventSlug']}>
                  <Events />
                </Route>
                <Route path='/create'>
                  <CreateEvent />
                </Route>
                <Route path='/sponsorships'>
                  <Sponsorships />
                </Route>
                <Route path='/partnerships'>
                  <Partnerships />
                </Route>
                {/* <Route path='/news'>
                  <News />
                </Route> */}
                <Route path='/about'>
                  <About />
                </Route>
              </Switch>
            </div>
            <Footer />
            <Snackbar
              anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
              key={`CovidDisclaimer`}
              open={showCovidDisclaimer}
            >
              <Alert elevation={6} variant='filled' severity='info'>
                <span className='snackbar'>
                  Due to the pandemic, please contact the event organizer to ensure events are still taking place as
                  planned.{' '}
                  <IconButton
                    size='small'
                    aria-label='close'
                    color='inherit'
                    onClick={() => setShowCovidDisclaimer(false)}
                  >
                    <CloseIcon fontSize='small' />
                  </IconButton>
                </span>
              </Alert>
            </Snackbar>
          </div>
        </AdsProvider>
      </ListFilterProvider>
      // </NewsProvider>
    )
  }
}

App.propTypes = {}

App.defaultProps = {}

export default App
