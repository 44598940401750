import moment from 'moment'

export const constructFilterQueryString = filters => {
  var endpoint = ''

  // Location
  // User has allowed location
  if (filters.coords && filters.coords.latitude && filters.coords.longitude) {
    // User has selected to specify location
    if (filters.locationFilterType === 2 && filters.city && filters.province) {
      endpoint = endpoint + `city=${filters.city}&province=${filters.province}`
    } else {
      endpoint = endpoint + `lat=${filters.coords.latitude}&long=${filters.coords.longitude}`
    }
  }
  // We have the users ISP location
  else {
    endpoint = endpoint + `city=${filters.city}&province=${filters.province}`
  }

  // Distance
  endpoint = endpoint + `&distance=${filters.distance}`

  // Event Types
  if (filters.eventTypes.length > 0) {
    endpoint = endpoint + `&${filters.eventTypes.map(x => `eventTypes=${x.id}`).join('&')}`
  }

  // Start Date
  endpoint = endpoint + `&startDate=${filters.startDate.utc().unix() + moment().utcOffset() * 60}`

  // End Date
  if (filters.endDate) {
    endpoint = endpoint + `&endDate=${filters.endDate.utc().unix() + moment().utcOffset() * 60}`
  }

  return endpoint
}
