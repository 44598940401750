import React, { useState } from 'react'
import * as yup from 'yup'
import { useFormik } from 'formik'
import { useMutation } from 'react-fetching-library'
import './ReportDialog.scss'

import Alert from '@material-ui/lab/Alert'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import FormControl from '@material-ui/core/FormControl'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import FormHelperText from '@material-ui/core/FormHelperText'
import Radio from '@material-ui/core/Radio'
import RadioGroup from '@material-ui/core/RadioGroup'
import Snackbar from '@material-ui/core/Snackbar'
import TextField from '@material-ui/core/TextField'

function ReportDialog(props) {
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [showSuccessAlert, setShowSuccessAlert] = useState(false)
  const [showErrorAlert, setShowErrorAlert] = useState(false)

  const submitReportEventAction = event => ({
    method: 'POST',
    endpoint: `/api/events/${props.eventId}/report`,
    body: event
  })
  const submitReportEvent = useMutation(submitReportEventAction, false)

  const reportSchema = yup.object().shape({
    reason: yup.string().required('A reason is required'),
    emailAddress: yup.string().email('Invalid email address')
  })

  const closeDialog = () => {
    formik.resetForm()
    props.onClose()
  }

  const formik = useFormik({
    initialValues: {
      description: '',
      emailAddress: ''
    },
    validationSchema: reportSchema,
    onSubmit: values => {
      setShowSuccessAlert(false)
      setShowErrorAlert(false)
      setIsSubmitting(true)
      submitReportEvent.mutate(values).then(result => {
        console.log('ReportEvent handleSubmit result', result)
        submitReportEvent.reset()
        if (result.status === 204) {
          // Successfully reported event
          setShowSuccessAlert(true)
          closeDialog()
        } else {
          setShowErrorAlert(true)
        }
        setIsSubmitting(false)
      })
    }
  })

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return
    }

    setShowSuccessAlert(false)
  }

  return (
    <>
      <Snackbar open={showSuccessAlert} autoHideDuration={6000} onClose={handleClose}>
        <Alert severity='success' onClose={handleClose}>
          Thank you for letting us know. We'll look into this as soon as possible.
        </Alert>
      </Snackbar>
      <Dialog className='report-dialog' onClose={props.onClose} open={props.showDialog} fullWidth maxWidth='xs'>
        <DialogTitle className='dialog-title'>Report Event</DialogTitle>
        <DialogContent>
          <form>
            <FormControl component='fieldset' required error={!!formik.errors.reason}>
              <RadioGroup required aria-label='reason' name='reason' onChange={formik.handleChange} color='secondary'>
                <FormControlLabel value='Spam' control={<Radio color='secondary' />} label='Spam' />
                <FormControlLabel value='Inappropriate' control={<Radio color='secondary' />} label='Inappropriate' />
                <FormControlLabel value='Duplicate' control={<Radio color='secondary' />} label='Duplicate' />
                <FormControlLabel value='other' control={<Radio color='secondary' />} label='Other' />
              </RadioGroup>
              <FormHelperText>{formik.errors.reason}</FormHelperText>
            </FormControl>
            <TextField
              id='description'
              name='description'
              label='Description'
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              disabled={isSubmitting}
              multiline
              fullWidth
              rows='4'
              margin='normal'
              variant='outlined'
              color='secondary'
              value={formik.values.description}
              error={formik.touched.description && !!formik.errors.description}
              helperText={formik.touched.description && formik.errors.description}
            />
            <TextField
              id='emailAddress'
              name='emailAddress'
              label='Your email'
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              disabled={isSubmitting}
              fullWidth
              margin='normal'
              variant='outlined'
              color='secondary'
              value={formik.values.emailAddress}
              error={formik.touched.emailAddress && !!formik.errors.emailAddress}
              helperText={
                formik.touched.emailAddress && formik.errors.emailAddress
                  ? formik.errors.emailAddress
                  : 'Just in case we have any questions'
              }
            />
            <input
              type='checkbox'
              name='ContactMeByFaxOnly'
              value='1'
              className='contact_me_by_fax_only'
              tabIndex='-1'
              autoComplete='off'
              onChange={formik.handleChange}
            />
          </form>
          {showErrorAlert && (
            <Alert severity='error' className='alert'>
              An error occurred. Please try again or contact us directly at{' '}
              <a href='mailto:support@drivercal.com'>support@drivercal.com</a>.
            </Alert>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={closeDialog}>Cancel</Button>
          <Button disabled={isSubmitting} variant='contained' color='secondary' onClick={formik.handleSubmit}>
            Report
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default ReportDialog
