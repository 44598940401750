import React, { useState, useEffect } from 'react'
import * as yup from 'yup'

import Grid from '@material-ui/core/Grid'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'

function AddOrganizerForm(props) {
  const [formState, setFormState] = useState({
    description: (props.organizer && props.organizer.description) || '',
    contactEmail: (props.organizer && props.organizer.contactEmail) || '',
    phoneNumber: (props.organizer && props.organizer.phoneNumber) || '',
  })

  const handleChange = event => {
    console.log('handleChange', event)
    setFormState({ ...formState, [event.target.name]: event.target.value })
  }

  const organizerSchema = yup.object().shape({
    description: yup.string().required(),
  })

  useEffect(() => {
    organizerSchema.isValid(formState).then(valid => {
      console.log('Add organizer form validity', valid)
      if (valid) {
        props.setOrganizer(formState)
      } else {
        props.setOrganizer(null)
      }
    })
  }, [formState])

  return (
    <React.Fragment>
      <Typography variant='subtitle1'>All new organizers are reviewed before the event is posted.</Typography>
      <Grid container>
        <Grid item xs={12}>
          <TextField
            required
            id='description'
            name='description'
            label='Company/Club/Organizer Name'
            fullWidth
            value={formState.description}
            onChange={handleChange}
            margin='normal'
            variant='outlined'
            color='secondary'
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            id='website'
            name='website'
            label='Website'
            placeholder='www.website.com'
            fullWidth
            value={formState.website}
            onChange={handleChange}
            margin='normal'
            variant='outlined'
            color='secondary'
          />
        </Grid>
        <Grid item xs={12} m={6}>
          <TextField
            id='contactFirstName'
            name='contactFirstName'
            label='Contact First Name'
            fullWidth
            value={formState.contactFirstName}
            onChange={handleChange}
            margin='normal'
            variant='outlined'
            color='secondary'
          />
        </Grid>
        <Grid item xs={12} m={6}>
          <TextField
            id='contactLastName'
            name='contactLastName'
            label='Contact Last Name'
            fullWidth
            value={formState.contactLastName}
            onChange={handleChange}
            margin='normal'
            variant='outlined'
            color='secondary'
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            id='contactEmail'
            name='contactEmail'
            label='Contact Email'
            fullWidth
            value={formState.contactEmail}
            onChange={handleChange}
            margin='normal'
            variant='outlined'
            color='secondary'
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            id='phoneNumber'
            name='phoneNumber'
            label='Contact Phone Number'
            fullWidth
            value={formState.phoneNumber}
            onChange={handleChange}
            margin='normal'
            variant='outlined'
            color='secondary'
          />
        </Grid>
      </Grid>
    </React.Fragment>
  )
}

export default AddOrganizerForm
