import React, { useState, useEffect } from 'react'
import { Helmet } from 'react-helmet'
import * as yup from 'yup'
import { useMutation } from 'react-fetching-library'
import Leaderboard from 'components/Ads/Leaderboard'
import BasicDetailsForm from './components/BasicDetailsForm'
import OrganizerForm from './components/OrganizerForm'
import LocationForm from './components/LocationForm'
import EventDatesForm from './components/EventDatesForm'
import { useResponsiveMode, Responsive } from 'hooks/useResponsive/useResponsive'
import moment from 'moment'
import './CreateEvent.scss'

import { makeStyles, useTheme } from '@material-ui/core/styles'
import Alert from '@material-ui/lab/Alert'
import AlertTitle from '@material-ui/lab/AlertTitle'
import Button from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress'
import Link from '@material-ui/core/Link'
import MobileStepper from '@material-ui/core/MobileStepper'
import Paper from '@material-ui/core/Paper'
import Stepper from '@material-ui/core/Stepper'
import Step from '@material-ui/core/Step'
import StepLabel from '@material-ui/core/StepLabel'
import Typography from '@material-ui/core/Typography'

import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft'
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight'

const useStyles = makeStyles(theme => ({
  appBar: {
    position: 'relative',
  },
  button: {
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(1),
  },
}))

const steps = ['Basic details', 'Organizer', 'Location', 'Date(s)']

function CreateEvent() {
  const [formState, setFormState] = useState({
    eventTypes: [],
    eventDates: [{ date: null, startTime: null, endTime: null }],
  })
  const [activeStep, setActiveStep] = useState(0)
  const [stepValidity, setStepValidity] = useState(false)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [showError, setShowError] = useState(false)
  const isMobile = useResponsiveMode() === Responsive.mobile
  const classes = useStyles()
  const theme = useTheme()

  const resetForm = () => {
    setFormState({
      eventTypes: [],
      eventDates: [{ date: null, startTime: null, endTime: null }],
    })
    setActiveStep(0)
    setStepValidity(false)
    setIsSubmitting(false)
    setShowError(false)
  }

  const submitEventAction = event => ({
    method: 'POST',
    endpoint: `/api/events`,
    body: event,
  })
  const submitEvent = useMutation(submitEventAction, false)

  const handleNext = () => {
    setActiveStep(activeStep + 1)
  }

  const handleBack = () => {
    setActiveStep(activeStep - 1)
  }

  const handleChange = event => {
    setFormState({ ...formState, [event.target.name]: event.target.value })
  }

  const formatPhoneNumber = phoneNumber => {
    return phoneNumber.replace(/[- )(]/g, '')
  }

  const handleSubmit = e => {
    e.preventDefault()
    setIsSubmitting(true)
    setShowError(false)

    var event = { ...formState }
    if (formState.eventTypes) {
      event.eventTypes = formState.eventTypes.map(x => x.id)
    }
    var eventDates = event.eventDates.map(eventDate => {
      if (eventDate.date != null) {
        const year = eventDate.date.year()
        const month = eventDate.date.month()
        const day = eventDate.date.date()
        const startDateTime = moment()
          .utc()
          .set({
            year: year,
            month: month,
            date: day,
            hour: eventDate.hasNoEventTime ? 0 : eventDate.startTime.hour(),
            minute: eventDate.hasNoEventTime ? 0 : eventDate.startTime.minute(),
          })
        const startTimestamp = startDateTime.unix()
        const endDateTime = moment()
          .utc()
          .set({
            year: year,
            month: month,
            date: day,
            hour: eventDate.hasNoEventTime ? 23 : eventDate.endTime.hour(),
            minute: eventDate.hasNoEventTime ? 59 : eventDate.endTime.minute(),
          })
        const endTimestamp = endDateTime.unix()

        return {
          startTimestamp,
          endTimestamp,
          hasNoEventTime: eventDate.hasNoEventTime,
        }
      }
    })
    var organizer = { ...formState.organizer }
    if (organizer.phoneNumber) {
      organizer.phoneNumber = formatPhoneNumber(organizer.phoneNumber)
    }
    delete event.location
    delete event.organizer
    delete event.eventDates
    submitEvent
      .mutate({ event: event, eventDates: eventDates, location: formState.location, organizer: organizer })
      .then(result => {
        if (result.status === 200) {
          setActiveStep(activeStep + 1)
        } else {
          console.error('Error creating event')
          submitEvent.reset()
          setShowError(true)
        }
        setIsSubmitting(false)
      })
  }

  const getStepContent = step => {
    switch (step) {
      case 0:
        return <BasicDetailsForm formState={formState} handleChange={handleChange} />
      case 1:
        return <OrganizerForm formState={formState} handleChange={handleChange} />
      case 2:
        return <LocationForm formState={formState} handleChange={handleChange} />
      case 3:
        return <EventDatesForm formState={formState} handleChange={handleChange} isDisabled={isSubmitting} />
      default:
        throw new Error('Unknown step')
    }
  }

  const basicDetailsSchema = yup.object().shape({
    eventName: yup.string().required(),
    description: yup.string().required(),
    eventPage: yup.string().required(),
    eventTypes: yup.array().required(),
    submittedBy: yup.string().email().required(),
  })

  const organizerSchema = yup.object().shape({
    organizer: yup.object().required(),
  })

  const locationSchema = yup.object().shape({
    location: yup.object().required(),
  })

  useEffect(() => {
    switch (activeStep) {
      case 0:
        basicDetailsSchema.isValid(formState).then(val => {
          setStepValidity(val)
        })
        break
      case 1:
        organizerSchema.isValid(formState).then(val => {
          setStepValidity(val)
        })
        break
      case 2:
        locationSchema.isValid(formState).then(val => {
          setStepValidity(val)
        })
        break
      case 3:
        var valid = false
        formState.eventDates.forEach((eventDate, index) => {
          if (index === 0) {
            if (
              eventDate &&
              eventDate.date &&
              ((eventDate.startTime && eventDate.endTime) || eventDate.hasNoEventTime)
            ) {
              valid = true
            } else {
              valid = false
            }
          } else {
            if (eventDate && !eventDate.date && !eventDate.startTime && !eventDate.endTime) {
              valid = true
            } else if (
              eventDate &&
              eventDate.date &&
              ((eventDate.startTime && eventDate.endTime) || eventDate.hasNoEventTime)
            ) {
              valid = true
            } else {
              valid = false
            }
          }
        })
        setStepValidity(valid)
        break
      case 4:
        // Final validation
        break
      default:
        throw new Error('Unknown step')
    }
  }, [formState, activeStep])

  return (
    <React.Fragment>
      <main className={`layout ${isMobile && 'mobile'}`}>
        <Helmet>
          <title>Add Event - DriverCal</title>
          <link rel='canonical' href='https://drivercal.com/create' />
        </Helmet>
        <Leaderboard />
        <form>
          <Paper className={`paper ${isMobile && 'mobile'}`}>
            <Typography
              variant={isMobile ? 'h4' : 'h3'}
              align='center'
              className={`page-header ${isMobile && 'mobile'}`}
            >
              ADD EVENT
            </Typography>
            {activeStep === 0 && (
              <Alert severity='info' className='bulk-events-alert'>
                <AlertTitle>Have multiple events to add?</AlertTitle>
                Send an email to{' '}
                <a href='mailto:events@drivercal.com?subject=Bulk event upload'>events@drivercal.com</a>. We will work
                with you to set up a bulk upload and get you back out hosting killer events.
              </Alert>
            )}
            {!isMobile && (
              <Stepper activeStep={activeStep} className='stepper'>
                {steps.map(label => (
                  <Step key={label}>
                    <StepLabel>{label}</StepLabel>
                  </Step>
                ))}
              </Stepper>
            )}
            <React.Fragment>
              {activeStep === steps.length ? (
                <React.Fragment>
                  {isSubmitting ? (
                    <div className='loading'>
                      <CircularProgress />
                    </div>
                  ) : (
                    <React.Fragment>
                      <Typography variant='h5' gutterBottom>
                        Thank you for your event.
                      </Typography>
                      <Typography variant='subtitle1'>
                        Your event has been submitted. It will be visible once we approve it.
                      </Typography>
                      <div className='text-link-container'>
                        <Typography variant='subtitle1'>
                          Please contact us if you have any questions or concerns at{' '}
                        </Typography>
                        <Link href='mailto:support@drivercal.com' color='secondary'>
                          <Typography variant='subtitle1'>support@drivercal.com</Typography>
                        </Link>
                      </div>
                      <div className='actions'>
                        <Link variant='contained' color='secondary' href='/events' className={classes.button}>
                          Back to events
                        </Link>
                        <Button variant='contained' color='secondary' onClick={resetForm} className={classes.button}>
                          Add another
                        </Button>
                      </div>
                    </React.Fragment>
                  )}
                </React.Fragment>
              ) : (
                <React.Fragment>
                  {getStepContent(activeStep)}
                  {isMobile ? (
                    <MobileStepper
                      className='mobile-stepper'
                      steps={steps.length}
                      position='static'
                      variant='text'
                      activeStep={activeStep}
                      nextButton={
                        activeStep === steps.length - 1 ? (
                          <Button
                            size='small'
                            variant='contained'
                            color='secondary'
                            onClick={handleSubmit}
                            disabled={!stepValidity || isSubmitting}
                          >
                            Submit
                          </Button>
                        ) : (
                          <Button size='small' onClick={handleNext} disabled={!stepValidity || isSubmitting}>
                            Next
                            {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
                          </Button>
                        )
                      }
                      backButton={
                        <Button size='small' onClick={handleBack} disabled={activeStep === 0 || isSubmitting}>
                          {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
                          Back
                        </Button>
                      }
                    />
                  ) : (
                    <div className='buttons'>
                      {activeStep !== 0 && (
                        <Button onClick={handleBack} className={classes.button} disabled={isSubmitting}>
                          Back
                        </Button>
                      )}
                      <Button
                        disabled={!stepValidity || isSubmitting}
                        variant='contained'
                        color='secondary'
                        onClick={activeStep === steps.length - 1 ? handleSubmit : handleNext}
                        className={classes.button}
                      >
                        {activeStep === steps.length - 1 ? 'Submit' : 'Next'}
                      </Button>
                    </div>
                  )}
                  {showError && (
                    <Alert className='form-submission-alert' severity='error'>
                      <div className='text-link-container'>
                        <Typography variant='subtitle1'>
                          There was an error adding your event. Please contact support at:
                        </Typography>
                        <Link href='mailto:support@drivercal.com'>
                          <Typography variant='subtitle1'>support@drivercal.com</Typography>
                        </Link>
                      </div>
                    </Alert>
                  )}
                </React.Fragment>
              )}
            </React.Fragment>
          </Paper>
          <input
            type='checkbox'
            name='ContactMeByFaxOnly'
            value='1'
            className='contact_me_by_fax_only'
            tabIndex='-1'
            autoComplete='off'
            onChange={handleChange}
          />
        </form>
      </main>
    </React.Fragment>
  )
}

export default CreateEvent
