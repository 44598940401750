import React, { createContext, useState } from 'react'
import { useLocation } from 'react-router-dom'
import moment from 'moment'
import { eventTypes } from '../helpers/eventTypeHelpers/eventTypeHelpers'

export const ListFilterContext = createContext()

function useQuery() {
  return new URLSearchParams(useLocation().search)
}

const ListFilterProvider = props => {
  let query = useQuery()
  const yourLocation = query.get('lat') && query.get('long')
  const specifiedLocation = query.get('city') && query.get('province')
  const eventTypeIds = query.getAll('eventTypes')
  const [filters, setFilters] = useState({
    distance: query.get('distance') || 250,
    locationFilterType: yourLocation ? 1 : specifiedLocation ? 2 : props.location ? 1 : 2,
    coords: yourLocation
      ? { latitude: query.get('lat'), longitude: query.get('long') }
      : specifiedLocation
      ? null
      : props.location
      ? props.location
      : null,
    city: yourLocation ? null : query.get('city') || (props.ipLocation ? props.ipLocation.city : null),
    province: yourLocation ? null : query.get('province') || (props.ipLocation ? props.ipLocation.state : undefined),
    eventTypes:
      eventTypeIds && eventTypeIds.length > 0
        ? eventTypeIds.map(eventTypeId => eventTypes.find(eventType => eventType.id === eventTypeId))
        : [],
    startDate:
      query.get('startDate') && query.get('startDate') > moment().utc().startOf('day').unix()
        ? moment.unix(query.get('startDate') - moment().utcOffset() * 60)
        : moment(),
    endDate: query.get('endDate') ? moment.unix(query.get('endDate')).endOf('day') : null,
  })
  const updateFilters = filters => {
    setFilters(filters)
  }
  return <ListFilterContext.Provider value={{ filters, updateFilters }}>{props.children}</ListFilterContext.Provider>
}

export default ListFilterProvider
