import React, { useState, useEffect } from 'react'
import { provinceOptions, getCountryFromProvinceValue } from 'helpers/locationHelpers'
import * as yup from 'yup'

import FormControl from '@material-ui/core/FormControl'
import Grid from '@material-ui/core/Grid'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import Select from '@material-ui/core/Select'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'

function AddLocationForm(props) {
  const [formState, setFormState] = useState({
    description: (props.location && props.location.description) || '',
    streetAddress: (props.location && props.location.streetAddress) || '',
    city: (props.location && props.location.city) || '',
    province: (props.location && props.location.province) || '',
    postalCode: (props.location && props.location.postalCode) || '',
    country: (props.location && props.location.country) || ''
  })
  const inputLabel = React.useRef(null)
  const [labelWidth, setLabelWidth] = React.useState(0)
  React.useEffect(() => {
    setLabelWidth(inputLabel.current.offsetWidth)
  }, [])

  const handleChange = event => {
    console.log('handleChange', event)
    if (event.target.name === 'province') {
      setFormState({
        ...formState,
        province: event.target.value,
        country: getCountryFromProvinceValue(event.target.value)
      })
    } else {
      setFormState({ ...formState, [event.target.name]: event.target.value })
    }
  }

  const renderProvinceOptions = () => {
    return provinceOptions.map(province => {
      return <MenuItem value={province.value}>{province.name}</MenuItem>
    })
  }

  const locationSchema = yup.object().shape({
    description: yup.string().required(),
    streetAddress: yup.string().required(),
    city: yup.string().required(),
    province: yup.string().required(),
    postalCode: yup.string().required(),
    country: yup.string().required()
  })

  useEffect(() => {
    console.log(formState)
    locationSchema.isValid(formState).then(valid => {
      console.log('Add location form validity', valid)
      if (valid) {
        props.setLocation(formState)
      } else {
        props.setLocation(null)
      }
    })
  }, [formState])

  return (
    <React.Fragment>
      <Typography variant='subtitle1'>All new locations are reviewed before the event is posted.</Typography>
      <Grid container>
        <Grid item xs={12}>
          <TextField
            required
            id='description'
            name='description'
            label='Description'
            fullWidth
            value={formState.description}
            onChange={handleChange}
            margin='normal'
            variant='outlined'
            color='secondary'
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            required
            id='streetAddress'
            name='streetAddress'
            label='Street address'
            fullWidth
            autoComplete='address-line1'
            value={formState.streetAddress}
            onChange={handleChange}
            margin='normal'
            variant='outlined'
            color='secondary'
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            required
            id='city'
            name='city'
            label='City'
            fullWidth
            autoComplete='address-level2'
            value={formState.city}
            onChange={handleChange}
            margin='normal'
            variant='outlined'
            color='secondary'
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormControl variant='outlined' margin='normal' required fullWidth>
            <InputLabel ref={inputLabel} id='province-label' color='secondary'>
              Province/State
            </InputLabel>
            <Select
              labelId='province-label'
              value={formState.province}
              onChange={handleChange}
              autoComplete='province'
              inputProps={{
                name: 'province',
                id: 'province'
              }}
              labelWidth={labelWidth}
              color='secondary'
            >
              <MenuItem value='' />
              {renderProvinceOptions()}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            required
            id='postalCode'
            name='postalCode'
            label='Postal code/Zip code'
            fullWidth
            autoComplete='postal-code'
            value={formState.postalCode}
            onChange={handleChange}
            margin='normal'
            variant='outlined'
            color='secondary'
          />
        </Grid>
      </Grid>
    </React.Fragment>
  )
}

export default AddLocationForm
