import React, { useEffect, useState } from 'react'
import { Link, useRouteMatch } from 'react-router-dom'
import Leaderboard from 'components/Ads/Leaderboard'
import AboutUs from 'components/AboutUs'
import ContactUs from 'components/ContactUs'
import PrivacyPolicy from 'components/PrivacyPolicy'
import { useResponsiveMode, Responsive } from 'hooks/useResponsive/useResponsive'
import './About.scss'

import Paper from '@material-ui/core/Paper'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import Typography from '@material-ui/core/Typography'

function About() {
  const ABOUT_US_TAB = 0
  const CONTACT_US_TAB = 1
  const PRIVACY_POLICY_TAB = 2
  const [selectedTab, setSelectedTab] = useState(ABOUT_US_TAB)
  const isMobile = useResponsiveMode() === Responsive.mobile
  let aboutUs = useRouteMatch('/about/about-us')
  let contactUs = useRouteMatch('/about/contact-us')
  let privacyPolicy = useRouteMatch('/about/privacy-policy')

  useEffect(() => {
    if (aboutUs) {
      setSelectedTab(ABOUT_US_TAB)
    }
  }, [aboutUs])

  useEffect(() => {
    if (contactUs) {
      setSelectedTab(CONTACT_US_TAB)
    }
  }, [contactUs])

  useEffect(() => {
    if (privacyPolicy) {
      setSelectedTab(PRIVACY_POLICY_TAB)
    }
  }, [privacyPolicy])

  return (
    <React.Fragment>
      <div className={`about ${isMobile && 'mobile'}`}>
        <Leaderboard />
        <Paper className={`paper ${isMobile && 'mobile'}`}>
          <Typography variant={isMobile ? 'h4' : 'h3'} paragraph align='center' className='page-header'>
            ABOUT
          </Typography>
          <Tabs
            value={selectedTab}
            indicatorColor='primary'
            textColor='primary'
            variant='fullWidth'
            onChange={(event, value) => setSelectedTab(value)}
            aria-label='About tabs'
          >
            <Tab component={Link} to='/about/about-us' label={<span className='tab-label'>About Us</span>} />
            <Tab component={Link} to='/about/contact-us' label={<span className='tab-label'>Contact Us</span>} />
            <Tab component={Link} to='/about/privacy-policy' label={<span className='tab-label'>Privacy</span>} />
          </Tabs>
          {selectedTab === ABOUT_US_TAB && <AboutUs />}
          {selectedTab === CONTACT_US_TAB && <ContactUs />}
          {selectedTab === PRIVACY_POLICY_TAB && <PrivacyPolicy />}
        </Paper>
      </div>
    </React.Fragment>
  )
}

About.propTypes = {}

About.defaultProps = {}

export default About
