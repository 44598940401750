import React from 'react'
import Leaderboard from 'components/Ads/Leaderboard'
import CorporateForm from './components/CorporateForm'
import { useResponsiveMode, Responsive } from 'hooks/useResponsive/useResponsive'
import './Partnerships.scss'

import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'

function Partnerships() {
  const isMobile = useResponsiveMode() === Responsive.mobile

  return (
    <React.Fragment>
      <div className={`partnerships ${isMobile && 'mobile'}`}>
        <Leaderboard />
        <Paper className={`paper ${isMobile && 'mobile'}`}>
          <Typography variant={isMobile ? 'h4' : 'h3'} paragraph align='center' className='page-header'>
            PARTNERSHIPS
          </Typography>
          <CorporateForm />
        </Paper>
      </div>
    </React.Fragment>
  )
}

Partnerships.propTypes = {}

Partnerships.defaultProps = {}

export default Partnerships
