import React from 'react'
import OrganizerForm from './OrganizerForm'
import { useMutation } from 'react-fetching-library'

function OrganizerFormContainer(props) {
  const fetchOrganizersAction = description => ({
    method: 'GET',
    endpoint: `/api/organizers?description=${description}`
  })
  const fetchOrganizers = useMutation(fetchOrganizersAction, false)

  return <OrganizerForm {...props} fetchOrganizers={fetchOrganizers} />
}

export default OrganizerFormContainer
