import React, { useState } from 'react'
import { Helmet } from 'react-helmet'
import * as yup from 'yup'
import { useFormik } from 'formik'
import { useMutation } from 'react-fetching-library'
import './ContactUs.scss'

import Alert from '@material-ui/lab/Alert'
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'

function ContactUs() {
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [showSuccessAlert, setShowSuccessAlert] = useState(false)
  const [showErrorAlert, setShowErrorAlert] = useState(false)

  const submitContactUsFormAction = event => ({
    method: 'POST',
    endpoint: `/api/contact-us`,
    body: event,
  })
  const submitContactUsForm = useMutation(submitContactUsFormAction, false)

  const contactUsSchema = yup.object().shape({
    name: yup.string().required('Name is required'),
    emailAddress: yup.string().email('Invalid email address').required('Email address is required'),
    message: yup.string().required('Message is required'),
  })

  const formik = useFormik({
    initialValues: {
      name: '',
      emailAddress: '',
      message: '',
    },
    validationSchema: contactUsSchema,
    onSubmit: values => {
      setShowSuccessAlert(false)
      setShowErrorAlert(false)
      setIsSubmitting(true)
      submitContactUsForm.mutate(values).then(result => {
        console.log('ContactUs handleSubmit result', result)
        submitContactUsForm.reset()
        if (result.status === 204) {
          setShowSuccessAlert(true)
          formik.resetForm()
        } else {
          setShowErrorAlert(true)
        }
        setIsSubmitting(false)
      })
    },
  })

  return (
    <React.Fragment>
      <Helmet>
        <title>Contact Us - DriverCal</title>
        <meta
          name='description'
          content={`Got a question? Feedback? We'd love to hear from you. Send us a message and we'll respond as soon as possible.`}
        />
        <link rel='canonical' href='https://drivercal.com/about/contact-us' />
      </Helmet>
      <form className='contact-us'>
        <Typography variant='subtitle1' align='center'>
          Got a question? Feedback? We'd love to hear from you. Send us a message and we'll respond as soon as possible.
        </Typography>
        <TextField
          required
          id='name'
          name='name'
          label='Name'
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          disabled={isSubmitting}
          fullWidth
          margin='normal'
          variant='outlined'
          color='secondary'
          value={formik.values.name}
          error={formik.touched.name && !!formik.errors.name}
          helperText={formik.touched.name && formik.errors.name}
        />
        <TextField
          required
          id='emailAddress'
          name='emailAddress'
          label='Email Address'
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          disabled={isSubmitting}
          fullWidth
          margin='normal'
          variant='outlined'
          color='secondary'
          value={formik.values.emailAddress}
          error={formik.touched.emailAddress && !!formik.errors.emailAddress}
          helperText={formik.touched.emailAddress && formik.errors.emailAddress}
        />
        <TextField
          required
          id='message'
          name='message'
          label='Message'
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          disabled={isSubmitting}
          multiline
          fullWidth
          rows='4'
          margin='normal'
          variant='outlined'
          color='secondary'
          value={formik.values.message}
          error={formik.touched.message && !!formik.errors.message}
          helperText={formik.touched.message && formik.errors.message}
        />
        <input
          type='checkbox'
          name='ContactMeByFaxOnly'
          value='1'
          className='contact_me_by_fax_only'
          tabIndex='-1'
          autoComplete='off'
          onChange={formik.handleChange}
        />
        {showSuccessAlert && (
          <Alert severity='success' className='alert'>
            Thank you for contacting us. We will get back to you as soon as possible.
          </Alert>
        )}
        {showErrorAlert && (
          <Alert severity='error' className='alert'>
            An error occurred. Please try again or contact us directly at{' '}
            <a href='mailto:support@drivercal.com'>support@drivercal.com</a>.
          </Alert>
        )}
        <div className='actions'>
          <Button disabled={isSubmitting} variant='contained' color='secondary' onClick={formik.handleSubmit}>
            Send Message
          </Button>
        </div>
      </form>
    </React.Fragment>
  )
}

export default ContactUs
