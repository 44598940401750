import React, { useState } from 'react'
import { Helmet } from 'react-helmet'
import * as yup from 'yup'
import { useFormik } from 'formik'
import { useMutation } from 'react-fetching-library'
import Leaderboard from 'components/Ads/Leaderboard'
import { useResponsiveMode, Responsive } from 'hooks/useResponsive/useResponsive'
import './Sponsorships.scss'

import Alert from '@material-ui/lab/Alert'
import Button from '@material-ui/core/Button'
import Checkbox from '@material-ui/core/Checkbox'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Paper from '@material-ui/core/Paper'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'

function Sponsorships() {
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [showSuccessAlert, setShowSuccessAlert] = useState(false)
  const [showErrorAlert, setShowErrorAlert] = useState(false)
  const isMobile = useResponsiveMode() === Responsive.mobile

  const submitFormAction = event => ({
    method: 'POST',
    endpoint: `/api/partnerships/driver`,
    body: event,
  })
  const submitForm = useMutation(submitFormAction, false)

  const sponsorshipFormSchema = yup.object().shape({
    name: yup.string().required('Name is required'),
    emailAddress: yup.string().email('Invalid email address').required('Email address is required'),
    phoneNumber: yup
      .string()
      .test('len', 'Invalid phone number', val => {
        if (val) {
          return val.length === 10 || val.length === 11
        }
        return true
      })
      .required('Phone number is required'),
    tellUsAboutYourself: yup
      .string()
      .max(5000, 'This can be no more than 5000 characters')
      .required('Tell us about yourself is required'),
    eventsInLast12Months: yup.string().max(5000, 'This can be no more than 5000 characters'),
    eventsInNext12Months: yup.string().max(5000, 'This can be no more than 5000 characters'),
    socialMediaAccounts: yup.string().max(1000, 'This can be no more than 1000 characters'),
    whyPartnerWithDrivercal: yup.string().max(5000, 'This can be no more than 5000 characters'),
  })

  const formik = useFormik({
    initialValues: {
      name: '',
      emailAddress: '',
      phoneNumber: '',
      tellUsAboutYourself: '',
      eventsInLast12Months: '',
      eventsInNext12Months: '',
      socialMediaAccounts: '',
      whyPartnerWithDrivercal: '',
      emailSubscribe: false,
    },
    validationSchema: sponsorshipFormSchema,
    onSubmit: values => {
      setShowSuccessAlert(false)
      setShowErrorAlert(false)
      setIsSubmitting(true)
      submitForm.mutate(values).then(result => {
        console.log('Sponsorships handleSubmit result', result)
        submitForm.reset()
        if (result.status === 204) {
          setShowSuccessAlert(true)
          formik.resetForm()
        } else {
          setShowErrorAlert(true)
        }
        setIsSubmitting(false)
      })
    },
  })

  const handleEmailSubscribeChange = value => event => {
    formik.handleChange({ target: { name: 'emailSubscribe', value: event.target.checked } })
  }

  return (
    <React.Fragment>
      <Helmet>
        <title>Sponsorships - DriverCal</title>
        <meta
          name='description'
          content={`It doesn’t matter what your age is, how fast you go, what you ride or where you finish! Our commitment is to recognize and support drivers (racers). The Drivercal.com sponsorship program seeks drivers who excel on and off the track and support motorsports in a positive and professional manner. If you feel you fit this profile and are looking for sponsorship support, then you might just be the next Drivercal.com sponsored driver.`}
        />
        <link rel='canonical' href='https://drivercal.com/sponsorships' />
      </Helmet>

      <div className={`sponsorships ${isMobile && 'mobile'}`}>
        <Leaderboard />
        <Paper className={`paper ${isMobile && 'mobile'}`}>
          <Typography variant={isMobile ? 'h4' : 'h3'} paragraph align='center' className='page-header'>
            SPONSORSHIPS
          </Typography>
          <form className='sponsorship-form'>
            <Typography variant='subtitle1' align='center'>
              It doesn’t matter what your age is, how fast you go, what you ride or where you finish! Our commitment is
              to recognize and support drivers (racers). The Drivercal.com sponsorship program seeks drivers who excel
              on and off the track and support motorsports in a positive and professional manner. If you feel you fit
              this profile and are looking for sponsorship support, then you might just be the next Drivercal.com
              sponsored driver.
            </Typography>
            <br />
            <Typography variant='subtitle1' align='center'>
              To apply for the sponsorship, each interested driver must submit the form below in full. Applicants may be
              contacted either by phone or email for additional information before a final decision is made.
            </Typography>
            <br />
            <Typography variant='subtitle1' align='center'>
              Remember you don’t have to be a top professional or champion to get a little support, you just have to be
              passionate about motorsports and DriverCal.com. Apply today, we can’t wait to have you on our Team!
            </Typography>
            <br />
            <TextField
              required
              id='name'
              name='name'
              label='Name'
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              disabled={isSubmitting}
              fullWidth
              margin='normal'
              variant='outlined'
              color='secondary'
              value={formik.values.name}
              error={formik.touched.name && !!formik.errors.name}
              helperText={formik.touched.name && formik.errors.name}
            />
            <TextField
              required
              id='emailAddress'
              name='emailAddress'
              label='Email Address'
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              disabled={isSubmitting}
              fullWidth
              margin='normal'
              variant='outlined'
              color='secondary'
              value={formik.values.emailAddress}
              error={formik.touched.emailAddress && !!formik.errors.emailAddress}
              helperText={formik.touched.emailAddress && formik.errors.emailAddress}
            />
            <TextField
              required
              id='phoneNumber'
              name='phoneNumber'
              label='Phone Number'
              fullWidth
              margin='normal'
              variant='outlined'
              color='secondary'
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.phoneNumber}
              disabled={isSubmitting}
              error={formik.touched.phoneNumber && !!formik.errors.phoneNumber}
              helperText={formik.touched.phoneNumber && formik.errors.phoneNumber}
            />
            <TextField
              required
              id='tellUsAboutYourself'
              name='tellUsAboutYourself'
              label='Tell us about yourself'
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              disabled={isSubmitting}
              multiline
              fullWidth
              rows='4'
              margin='normal'
              variant='outlined'
              color='secondary'
              value={formik.values.tellUsAboutYourself}
              error={formik.touched.tellUsAboutYourself && !!formik.errors.tellUsAboutYourself}
              helperText={
                formik.touched.tellUsAboutYourself && formik.errors.tellUsAboutYourself
                  ? formik.errors.tellUsAboutYourself
                  : 'Where do you race? Where do you drive? How did you get into the sport? We want to get to know you. Not your numbers. Dig deep and tell us who you really are and what makes you that person.'
              }
            />
            <TextField
              id='eventsInLast12Months'
              name='eventsInLast12Months'
              label='What series and/or events did you compete in over the last 12 months?'
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              disabled={isSubmitting}
              multiline
              fullWidth
              rows='4'
              margin='normal'
              variant='outlined'
              color='secondary'
              value={formik.values.eventsInLast12Months}
              error={formik.touched.eventsInLast12Months && !!formik.errors.eventsInLast12Months}
              helperText={formik.touched.eventsInLast12Months && formik.errors.eventsInLast12Months}
            />
            <TextField
              id='eventsInNext12Months'
              name='eventsInNext12Months'
              label='What series and/or events do you plan to compete in over the next 12 months?'
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              disabled={isSubmitting}
              multiline
              fullWidth
              rows='4'
              margin='normal'
              variant='outlined'
              color='secondary'
              value={formik.values.eventsInNext12Months}
              error={formik.touched.eventsInNext12Months && !!formik.errors.eventsInNext12Months}
              helperText={formik.touched.eventsInNext12Months && formik.errors.eventsInNext12Months}
            />
            <TextField
              id='socialMediaAccounts'
              name='socialMediaAccounts'
              label='Social media links (Facebook, Instagram, etc.)'
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              disabled={isSubmitting}
              fullWidth
              margin='normal'
              variant='outlined'
              color='secondary'
              value={formik.values.socialMediaAccounts}
              error={formik.touched.socialMediaAccounts && !!formik.errors.socialMediaAccounts}
              helperText={formik.touched.socialMediaAccounts && formik.errors.socialMediaAccounts}
            />
            <TextField
              id='whyPartnerWithDrivercal'
              name='whyPartnerWithDrivercal'
              label='Why would you like to be sponsored by DriverCal?'
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              disabled={isSubmitting}
              multiline
              fullWidth
              rows='4'
              margin='normal'
              variant='outlined'
              color='secondary'
              value={formik.values.whyPartnerWithDrivercal}
              error={formik.touched.whyPartnerWithDrivercal && !!formik.errors.whyPartnerWithDrivercal}
              helperText={formik.touched.whyPartnerWithDrivercal && formik.errors.whyPartnerWithDrivercal}
            />
            <FormControlLabel
              checked={formik.values.emailSubscribe}
              control={
                <Checkbox
                  name='emailSubscribe'
                  value={formik.values.emailSubscribe}
                  onChange={handleEmailSubscribeChange()}
                />
              }
              label='Click here if you would like to keep up-to-date with our progress and future programs'
            />
            <input
              type='checkbox'
              name='ContactMeByFaxOnly'
              value='1'
              className='contact_me_by_fax_only'
              tabIndex='-1'
              autoComplete='off'
              onChange={formik.handleChange}
            />
            {showSuccessAlert && (
              <Alert severity='success' className='alert'>
                Thank you for reaching out. We will get back to you as soon as possible.
              </Alert>
            )}
            {showErrorAlert && (
              <Alert severity='error' className='alert'>
                An error occurred. Please try again or contact us directly at{' '}
                <a href='mailto:support@drivercal.com'>support@drivercal.com</a>.
              </Alert>
            )}
            <div className='actions'>
              <Button disabled={isSubmitting} variant='contained' color='secondary' onClick={formik.handleSubmit}>
                Submit
              </Button>
            </div>
          </form>
        </Paper>
      </div>
    </React.Fragment>
  )
}

export default Sponsorships
