import React, { useState } from 'react'
import './ShareDialog.scss'

import Avatar from '@material-ui/core/Avatar'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemAvatar from '@material-ui/core/ListItemAvatar'
import ListItemText from '@material-ui/core/ListItemText'
import TextField from '@material-ui/core/TextField'

import PersonIcon from '@material-ui/icons/Person'

function ShareDialog(props) {
  const [hasLinkBeenCopied, setHasLinkBeenCopied] = useState(false)
  var eventPage = `www.drivercal.com/events/${props.event.slug}`

  const shareToFacebook = () => {
    var url = `https://www.facebook.com/sharer/sharer.php?u=https://${eventPage}`
    openPage(url)
  }

  const shareToTwitter = () => {
    var url = `https://twitter.com/intent/tweet?url=https://${eventPage}&text=DriverCal.com%20event%20`
    openPage(url)
  }

  const shareToEmail = () => {
    var url = `mailto:?subject=${props.event.eventName} at ${props.event.location.description}&body=See more details at ${eventPage}`
    openPage(url)
  }

  const openPage = url => {
    var win = window.open(url, '_blank')
    if (win) {
      // Browser allows it to be opened
      win.focus()
    } else {
      alert('Please allow popups ')
    }
    props.onClose()
  }

  const copyLink = () => {
    var input = document.getElementById('copy-text')
    input.select()
    input.setSelectionRange(0, 99999)
    document.execCommand('copy')
    setHasLinkBeenCopied(true)
  }

  return (
    <Dialog onClose={props.onClose} open={props.showDialog} fullWidth maxWidth='xs'>
      <DialogTitle>Share</DialogTitle>
      <List>
        <ListItem button onClick={shareToFacebook} key='facebook'>
          <ListItemAvatar>
            <Avatar>
              <PersonIcon />
            </Avatar>
          </ListItemAvatar>
          <ListItemText primary='Facebook' />
        </ListItem>
        <ListItem button onClick={shareToTwitter} key='twitter'>
          <ListItemAvatar>
            <Avatar>
              <PersonIcon />
            </Avatar>
          </ListItemAvatar>
          <ListItemText primary='Twitter' />
        </ListItem>
        <ListItem button onClick={shareToEmail} key='email'>
          <ListItemAvatar>
            <Avatar>
              <PersonIcon />
            </Avatar>
          </ListItemAvatar>
          <ListItemText primary='Email' />
        </ListItem>
        <ListItem button onClick={copyLink}>
          <ListItemText
            primary={hasLinkBeenCopied ? 'Link copied' : 'Tap to copy link'}
            secondary={<TextField fullWidth disabled className='disabled-link' value={eventPage} />}
          />
          <input aria-hidden={true} id='copy-text' className='hide' value={eventPage} />
        </ListItem>
      </List>
    </Dialog>
  )
}

export default ShareDialog
