import React from 'react'
import { getEventTypeDescription, getEventTypeColour } from 'helpers/eventTypeHelpers'
import { Link as RouterLink } from 'react-router-dom'
import moment from 'moment'
import './UpcomingEventItem.scss'

import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import Chip from '@material-ui/core/Chip'
import Link from '@material-ui/core/Link'
import Typography from '@material-ui/core/Typography'

import DateTimeIcon from '@material-ui/icons/Schedule'
import LocationIcon from '@material-ui/icons/Room'

function UpcomingEventItem(props) {
  var startDateTime = moment.unix(props.item.startTimestamp).utc()
  var endDateTime = moment.unix(props.item.endTimestamp).utc()

  const onRenderEventTypes = eventTypes => {
    if (eventTypes) {
      return eventTypes.map(eventType => {
        return (
          <Chip
            key={eventType}
            color='primary'
            className={`event-list-type ${getEventTypeColour(eventType)}`}
            size='small'
            label={getEventTypeDescription(eventType)}
          />
        )
      })
    }
  }

  return (
    <Link className='event-item-link' component={RouterLink} to={`/events/${props.item.slug}`} key={props.item._id}>
      <Card variant='outlined' elevation={3} className={`upcoming-event-item`}>
        <CardContent className='event-list-date'>
          <Typography variant='h4'>{endDateTime.date()}</Typography>
          <Typography variant='subtitle1'>{endDateTime.format('MMM').toUpperCase()}</Typography>
        </CardContent>
        <CardContent className='upcoming-event-details'>
          <Typography variant='h5' className='event-name '>
            {props.item.eventName}
          </Typography>
          <div className='list-item-info'>
            <LocationIcon />
            <Typography variant='subtitle2'>{props.item.location.description}</Typography>
          </div>
          <div className='list-item-info'>
            <DateTimeIcon />
            <Typography variant='subtitle2'>
              {startDateTime.format('h:mma')} - {endDateTime.format('h:mma')}
            </Typography>
          </div>
          {onRenderEventTypes(props.item.eventTypes)}
        </CardContent>
      </Card>
    </Link>
  )
}

export default UpcomingEventItem
