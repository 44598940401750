import React from 'react'
import './NavBar.scss'

import { makeStyles } from '@material-ui/core/styles'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'

const useStyles = makeStyles(theme => ({
  appBar: {
    position: 'relative'
  },
  logo: {
    flexGrow: 1,
    cursor: 'pointer'
  }
}))

function NavBar(props) {
  const classes = useStyles()

  return (
    <div className='nav-bar'>
      <AppBar position='static' className={classes.appBar}>
        <Toolbar className='nav-bar' disableGutters>
          {props.navItems}
        </Toolbar>
      </AppBar>
    </div>
  )
}

export default NavBar
