export const provinceOptions = [
  { key: 'AB', text: 'AB', value: 'AB', name: 'Alberta', country: 'CA' },
  { key: 'BC', text: 'BC', value: 'BC', name: 'British Columbia', country: 'CA' },
  { key: 'MB', text: 'MB', value: 'MB', name: 'Manitoba', country: 'CA' },
  { key: 'NB', text: 'NB', value: 'NB', name: 'New Brunswick', country: 'CA' },
  { key: 'NF', text: 'NF', value: 'NF', name: 'Newfoundland and Labrador', country: 'CA' },
  { key: 'NS', text: 'NS', value: 'NS', name: 'Nova Scotia', country: 'CA' },
  { key: 'NT', text: 'NT', value: 'NT', name: 'Northwest Territories', country: 'CA' },
  { key: 'NU', text: 'NU', value: 'NU', name: 'Nunavut', country: 'CA' },
  { key: 'ON', text: 'ON', value: 'ON', name: 'Ontario', country: 'CA' },
  { key: 'PE', text: 'PE', value: 'PE', name: 'Prince Edward Island', country: 'CA' },
  { key: 'QC', text: 'QC', value: 'QC', name: 'Quebec', country: 'CA' },
  { key: 'SK', text: 'SK', value: 'SK', name: 'Saskatchewan', country: 'CA' },
  { key: 'YT', text: 'YT', value: 'YT', name: 'Yukon', country: 'CA' },
  { key: 'AL', text: 'AL', value: 'AL', name: 'Alabama', country: 'USA' },
  { key: 'AK', text: 'AK', value: 'AK', name: 'Alaska', country: 'USA' },
  { key: 'AZ', text: 'AZ', value: 'AZ', name: 'Arizona', country: 'USA' },
  { key: 'AR', text: 'AR', value: 'AR', name: 'Arkansas', country: 'USA' },
  { key: 'CA', text: 'CA', value: 'CA', name: 'California', country: 'USA' },
  { key: 'CO', text: 'CO', value: 'CO', name: 'Colorado', country: 'USA' },
  { key: 'CT', text: 'CT', value: 'CT', name: 'Connecticut', country: 'USA' },
  { key: 'DE', text: 'DE', value: 'DE', name: 'Delaware', country: 'USA' },
  { key: 'FL', text: 'FL', value: 'FL', name: 'Florida', country: 'USA' },
  { key: 'GA', text: 'GA', value: 'GA', name: 'Georgia', country: 'USA' },
  { key: 'HI', text: 'HI', value: 'HI', name: 'Hawaii', country: 'USA' },
  { key: 'ID', text: 'ID', value: 'ID', name: 'Idaho', country: 'USA' },
  { key: 'IL', text: 'IL', value: 'IL', name: 'Illinois', country: 'USA' },
  { key: 'IN', text: 'IN', value: 'IN', name: 'Indiana', country: 'USA' },
  { key: 'IA', text: 'IA', value: 'IA', name: 'Iowa', country: 'USA' },
  { key: 'KS', text: 'KS', value: 'KS', name: 'Kansas', country: 'USA' },
  { key: 'KY', text: 'KY', value: 'KY', name: 'Kentucky', country: 'USA' },
  { key: 'LA', text: 'LA', value: 'LA', name: 'Louisiana', country: 'USA' },
  { key: 'ME', text: 'ME', value: 'ME', name: 'Maine', country: 'USA' },
  { key: 'MD', text: 'MD', value: 'MD', name: 'Maryland', country: 'USA' },
  { key: 'MA', text: 'MA', value: 'MA', name: 'Massachusetts', country: 'USA' },
  { key: 'MI', text: 'MI', value: 'MI', name: 'Michigan', country: 'USA' },
  { key: 'MN', text: 'MN', value: 'MN', name: 'Minnesota', country: 'USA' },
  { key: 'MS', text: 'MS', value: 'MS', name: 'Mississippi', country: 'USA' },
  { key: 'MO', text: 'MO', value: 'MO', name: 'Missouri', country: 'USA' },
  { key: 'MT', text: 'MT', value: 'MT', name: 'Montana', country: 'USA' },
  { key: 'NE', text: 'NE', value: 'NE', name: 'Nebraska', country: 'USA' },
  { key: 'NV', text: 'NV', value: 'NV', name: 'Nevada', country: 'USA' },
  { key: 'NH', text: 'NH', value: 'NH', name: 'New Hampshire', country: 'USA' },
  { key: 'NJ', text: 'NJ', value: 'NJ', name: 'New Jersey', country: 'USA' },
  { key: 'NM', text: 'NM', value: 'NM', name: 'New Mexico', country: 'USA' },
  { key: 'NY', text: 'NY', value: 'NY', name: 'New York', country: 'USA' },
  { key: 'NC', text: 'NC', value: 'NC', name: 'North Carolina', country: 'USA' },
  { key: 'ND', text: 'ND', value: 'ND', name: 'North Dakota', country: 'USA' },
  { key: 'OH', text: 'OH', value: 'OH', name: 'Ohio', country: 'USA' },
  { key: 'OK', text: 'OK', value: 'OK', name: 'Oklahoma', country: 'USA' },
  { key: 'OR', text: 'OR', value: 'OR', name: 'Oregon', country: 'USA' },
  { key: 'PA', text: 'PA', value: 'PA', name: 'Pennsylvania', country: 'USA' },
  { key: 'RI', text: 'RI', value: 'RI', name: 'Rhode Island', country: 'USA' },
  { key: 'SC', text: 'SC', value: 'SC', name: 'South Carolina', country: 'USA' },
  { key: 'SD', text: 'SD', value: 'SD', name: 'South Dakota', country: 'USA' },
  { key: 'TN', text: 'TN', value: 'TN', name: 'Tennessee', country: 'USA' },
  { key: 'TX', text: 'TX', value: 'TX', name: 'Texas', country: 'USA' },
  { key: 'UT', text: 'UT', value: 'UT', name: 'Utah', country: 'USA' },
  { key: 'VT', text: 'VT', value: 'VT', name: 'Vermont', country: 'USA' },
  { key: 'VA', text: 'VA', value: 'VA', name: 'Virginia', country: 'USA' },
  { key: 'WA', text: 'WA', value: 'WA', name: 'Washington', country: 'USA' },
  { key: 'WV', text: 'WV', value: 'WV', name: 'West Virginia', country: 'USA' },
  { key: 'WI', text: 'WI', value: 'WI', name: 'Wisconsin', country: 'USA' },
  { key: 'WY', text: 'WY', value: 'WY', name: 'Wyoming', country: 'USA' }
]

export const countryOptions = [{ key: 'CA', text: 'Canada', value: 'CA' }, { key: 'USA', text: 'USA', value: 'USA' }]

export const getProvinceValueFromName = name => {
  var province = provinceOptions.find(element => element.name === name)
  return province.value
}

export const getCountryFromProvinceValue = value => {
  var province = provinceOptions.find(element => element.value === value)
  return province.country
}
