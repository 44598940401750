import React from 'react'
import moment from 'moment'

import Avatar from '@material-ui/core/Avatar'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemAvatar from '@material-ui/core/ListItemAvatar'
import ListItemText from '@material-ui/core/ListItemText'

import PersonIcon from '@material-ui/icons/Person'

function AddToCalendarDialog(props) {
  var startDate = moment.unix(props.event.startTimestamp).utc().format('YYYYMMDDTHHmmSS')
  var endDate = moment.unix(props.event.endTimestamp).utc().format('YYYYMMDDTHHmmSS')
  var location = `${props.event.location.streetAddress}, ${props.event.location.city}, ${props.event.location.province} ${props.event.location.postalCode}`

  const addToGoogleCalendar = () => {
    var url = `https://calendar.google.com/calendar/render?action=TEMPLATE&text=${props.event.eventName}&dates=${startDate}/${endDate}&details=${props.event.description}&location=${location}&sprop=website:www.drivercal.com/events/${props.event.slug}&sprop=name:DriverCal.com`
    openPage(encodeURI(url))
  }

  const addToOutlookCalendar = () => {
    var startDateWithOffset = moment.unix(props.event.startTimestamp).utc().format('YYYY-MM-DDTHH:mm:SS')
    var endDateWithOffset = moment.unix(props.event.endTimestamp).utc().format('YYYY-MM-DDTHH:mm:SS')
    // https://interactiondesignfoundation.github.io/add-event-to-calendar-docs/services/outlook-web.html
    var url = `https://outlook.live.com/owa/?path=/calendar/action/compose&rru=addevent&startdt=${startDateWithOffset}&enddt=${endDateWithOffset}&subject=${props.event.eventName}&body=${props.event.description}&location=${location}`
    openPage(encodeURI(url))
  }

  const openPage = url => {
    var win = window.open(url, '_blank')
    if (win) {
      // Browser allows it to be opened
      win.focus()
    } else {
      alert('Please allow popups ')
    }
    props.onClose()
  }

  return (
    <Dialog onClose={props.onClose} open={props.showDialog}>
      <DialogTitle>Add to calendar</DialogTitle>
      <List>
        <ListItem button onClick={addToGoogleCalendar} key='google'>
          <ListItemAvatar>
            <Avatar>
              <PersonIcon />
            </Avatar>
          </ListItemAvatar>
          <ListItemText primary='Google' />
        </ListItem>
        <ListItem button onClick={addToOutlookCalendar} key='outlook'>
          <ListItemAvatar>
            <Avatar>
              <PersonIcon />
            </Avatar>
          </ListItemAvatar>
          <ListItemText primary='Outlook' />
        </ListItem>
      </List>
    </Dialog>
  )
}

export default AddToCalendarDialog
