import React from 'react'
import './EventDatesForm.scss'

import { Form } from 'semantic-ui-react'

import Button from '@material-ui/core/Button'
import Divider from '@material-ui/core/Divider'
import FormControl from '@material-ui/core/FormControl'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Grid from '@material-ui/core/Grid'
import IconButton from '@material-ui/core/IconButton'
import Switch from '@material-ui/core/Switch'
import Tooltip from '@material-ui/core/Tooltip'
import Typography from '@material-ui/core/Typography'

import AddIcon from '@material-ui/icons/Add'
import RemoveIcon from '@material-ui/icons/Remove'

import moment from 'moment'
import MomentUtils from '@date-io/moment'
import { MuiPickersUtilsProvider, KeyboardTimePicker, KeyboardDatePicker } from '@material-ui/pickers'

function EventDatesForm(props) {
  const handleDateTimeChange = (name, date, index) => {
    var eventDates = props.formState.eventDates
    var eventDate = props.formState.eventDates[index]
    eventDate[name] = date
    eventDates[index] = eventDate
    props.handleChange({ target: { name: 'eventDates', value: eventDates } })
  }

  const isDateEntryValid = index => {
    var eventDate = props.formState.eventDates[index]
    if (eventDate && eventDate.date && ((eventDate.startTime && eventDate.endTime) || eventDate.hasNoEventTime)) {
      return true
    } else {
      return false
    }
  }

  const renderDates = () => {
    var dateInputs = props.formState.eventDates.map((eventDate, index) => {
      return (
        <Form.Group className='date-container'>
          {isDateEntryValid(index) && props.formState.eventDates.length > index + 1 ? (
            <Grid container>
              <Grid item xs={10}>
                {eventDate.hasNoEventTime ? (
                  <Typography className='event-date'>{eventDate.date.format('MMMM Do YYYY')}</Typography>
                ) : (
                  <Typography className='event-date'>
                    {eventDate.date.format('MMMM Do YYYY')}, {eventDate.startTime.format('h:mm a')} -{' '}
                    {eventDate.endTime.format('h:mm a')}
                  </Typography>
                )}
              </Grid>
              <Grid item xs={2} className='remove-button-container'>
                <IconButton
                  className='remove-button'
                  color='primary'
                  onClick={() => {
                    var newDates = [...props.formState.eventDates]
                    newDates.splice(index, 1)
                    props.handleChange({
                      target: { name: 'eventDates', value: [...newDates] },
                    })
                  }}
                >
                  <RemoveIcon />
                </IconButton>
              </Grid>
            </Grid>
          ) : (
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <KeyboardDatePicker
                  required
                  id={`date${index}`}
                  key={`date${index}`}
                  name='date'
                  label='Date'
                  value={eventDate.date}
                  onChange={date => handleDateTimeChange('date', date, index)}
                  minDate={moment()}
                  fullWidth
                  inputVariant='outlined'
                  color='secondary'
                  disabled={props.isDisabled}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <KeyboardTimePicker
                  required={!eventDate.hasNoEventTime}
                  mask='__:__ _M'
                  id={`startTime${index}`}
                  key={`startTime${index}`}
                  name='startTime'
                  label='Start time'
                  value={eventDate.startTime}
                  placeholder='08:00 AM'
                  initialFocusedDate='01/01/1900 8:00:00'
                  onChange={time => handleDateTimeChange('startTime', time, index)}
                  fullWidth
                  inputVariant='outlined'
                  color='secondary'
                  disabled={props.isDisabled || eventDate.hasNoEventTime}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <KeyboardTimePicker
                  required={!eventDate.hasNoEventTime}
                  mask='__:__ _M'
                  id={`endTime${index}`}
                  key={`endTime${index}`}
                  name='endTime'
                  label='End time'
                  value={eventDate.endTime}
                  placeholder='05:00 PM'
                  initialFocusedDate='01/01/1900 17:00:00'
                  onChange={time => handleDateTimeChange('endTime', time, index)}
                  fullWidth
                  inputVariant='outlined'
                  color='secondary'
                  disabled={props.isDisabled || eventDate.hasNoEventTime}
                />
              </Grid>
              <Grid item xs={6}>
                <FormControlLabel
                  control={
                    <Switch
                      checked={eventDate.hasNoEventTime}
                      onChange={(event, checked) => handleDateTimeChange('hasNoEventTime', checked, index)}
                      name='hasNoEventTime'
                    />
                  }
                  label={'Direct users to event website for timing'}
                />
              </Grid>
            </Grid>
          )}
          <Grid container spacing={2}>
            <Grid item xs={6}>
              {index === props.formState.eventDates.length - 1 && (
                <Tooltip title='Finish entering the date before adding another'>
                  <span>
                    <Button
                      disabled={!isDateEntryValid(index) || props.isDisabled}
                      color='secondary'
                      onClick={() =>
                        props.handleChange({
                          target: {
                            name: 'eventDates',
                            value: [...props.formState.eventDates, { date: null, startTime: null, endTime: null }],
                          },
                        })
                      }
                      startIcon={<AddIcon />}
                    >
                      Add another
                    </Button>
                  </span>
                </Tooltip>
              )}
            </Grid>
          </Grid>
          {props.formState.eventDates.length > 1 && index + 1 !== props.formState.eventDates.length && (
            <Divider className='divider' />
          )}
        </Form.Group>
      )
    })

    return dateInputs
  }

  return (
    <React.Fragment>
      <Typography variant='h6' gutterBottom>
        Event Date(s)
      </Typography>
      <Typography variant='subtitle2'>You can add multiple dates and we will add one event per date.</Typography>
      <Grid container>
        <Grid item xs={12}>
          <FormControl component='fieldset' margin='normal'>
            <MuiPickersUtilsProvider utils={MomentUtils}>
              <Grid container>{renderDates()}</Grid>
            </MuiPickersUtilsProvider>
          </FormControl>
        </Grid>
      </Grid>
    </React.Fragment>
  )
}

export default EventDatesForm
