export const AD_TYPES = {
  Leaderboard: 1,
  ListSide: 2,
  Event: 3,
}

export const AD_REACH = {
  National: {
    id: 1,
    description: 'National',
  },
  Regional: {
    id: 2,
    description: 'Regional',
  },
  Organizer: {
    id: 3,
    description: 'Organizer',
  },
}
