import React, { useContext, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import Chance from 'chance'
import { AdsContext } from 'context/AdsContext'
import { AD_REACH } from 'helpers/adHelpers'
import './ListSideAds.scss'

function ListSideAds(props) {
  const [listSideAdsToDisplay, setListSideAdsToDisplay] = useState(null)
  const { listSideAds, putAdView } = useContext(AdsContext)
  const chance = new Chance()

  const getSource = listSideAd => {
    return listSideAd.desktopSource
  }

  const getListSideAds = (listSideAds, reach) => {
    if (listSideAds) {
      var filteredListSideAds = [...listSideAds]

      if (reach) {
        filteredListSideAds = filteredListSideAds.filter(listSideAd => reach.includes(listSideAd.reach))
      }

      if (filteredListSideAds && filteredListSideAds.length > 0) {
        var ads = []
        for (var i = 0; i < props.quantity; i++) {
          var adsNotBeingDisplayed = filteredListSideAds.filter(ad => !ads.includes(ad))
          if (adsNotBeingDisplayed.length > 0) {
            var weights = adsNotBeingDisplayed.map(listSideAd => {
              return listSideAd.weight
            })
            var newListSideAd = chance.weighted(adsNotBeingDisplayed, weights)
            ads.push(newListSideAd)
            putAdView(newListSideAd._id)
          }
        }
        if (ads.length > 0) {
          setListSideAdsToDisplay(ads)
        }
      }
    }
  }

  const getAdPlaceholders = () => {
    for (var i = 0; i < props.quantity; i++) {
      return <div className='ad placeholder' />
    }
  }

  useEffect(() => {
    if (listSideAds) {
      getListSideAds(listSideAds, props.reach)
    }
  }, [listSideAds, props.reach])

  return (
    <>
      {listSideAdsToDisplay ? (
        listSideAdsToDisplay.map(listSideAd => (
          <div className='list-side-ad' key={listSideAd._id}>
            <a href={listSideAd.redirectUrl} key={listSideAd._id} target='_blank'>
              <img src={getSource(listSideAd)} alt={listSideAd.description} className='ad' />
            </a>
          </div>
        ))
      ) : (
        <>{getAdPlaceholders()}</>
      )}
    </>
  )
}

ListSideAds.propTypes = {
  quantity: PropTypes.number,
  reach: PropTypes.array,
}

ListSideAds.defaultProps = {
  quantity: 1,
  reach: [AD_REACH.National.id, AD_REACH.Regional.id],
}

export default ListSideAds
