import { useState, useEffect } from 'react'

export const Responsive = {
  mobile: {
    maxWidth: 599
  },
  tablet: {
    minWidth: 600,
    maxWidth: 1199
  },
  desktop: {
    minWidth: 1200
  }
}

export function useResponsiveMode() {
  const [responsiveMode, setResponsiveMode] = useState(getResponsiveMode())

  useEffect(() => {
    function handleResize() {
      setResponsiveMode(getResponsiveMode())
    }

    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  return responsiveMode
}

function getWindowWidth() {
  const { innerWidth: width } = window
  return width
}

function getResponsiveMode() {
  const width = getWindowWidth()
  if (width <= Responsive.mobile.maxWidth) {
    return Responsive.mobile
  } else if (width >= Responsive.tablet.minWidth && width <= Responsive.tablet.maxWidth) {
    return Responsive.tablet
  } else {
    return Responsive.desktop
  }
}
