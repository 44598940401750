import React, { useState, useEffect } from 'react'
import * as yup from 'yup'
import { useFormik } from 'formik'
import { eventTypes } from 'helpers/eventTypeHelpers'
import { provinceOptions } from 'helpers/locationHelpers'
import './EventListFilterForm.scss'

import Alert from '@material-ui/lab/Alert'
import Button from '@material-ui/core/Button'
import Checkbox from '@material-ui/core/Checkbox'
import Divider from '@material-ui/core/Divider'
import FormControl from '@material-ui/core/FormControl'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import FormHelperText from '@material-ui/core/FormHelperText'
import FormLabel from '@material-ui/core/FormLabel'
import Grid from '@material-ui/core/Grid'
import IconButton from '@material-ui/core/IconButton'
import MenuItem from '@material-ui/core/MenuItem'
import Radio from '@material-ui/core/Radio'
import Select from '@material-ui/core/Select'
import Slider from '@material-ui/core/Slider'
import Snackbar from '@material-ui/core/Snackbar'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'

import ArrowDownIcon from '@material-ui/icons/KeyboardArrowDown'
import ArrowUpIcon from '@material-ui/icons/KeyboardArrowUp'
import CloseIcon from '@material-ui/icons/Close'

import moment from 'moment'
import MomentUtils from '@date-io/moment'
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers'

const radiusOptions = [
  { key: '25', label: '25', value: 25 },
  { key: '250', label: '250', value: 250 },
  { key: '500', label: '500', value: 500 },
  { key: '1000', label: '1000', value: 1000 },
]

const MenuProps = {
  PaperProps: {
    style: {
      width: 250,
    },
  },
}

function EventListFilterForm(props) {
  const [showGeolocationError, setShowGeolocationError] = useState(false)
  const [showUserDeniedGeolocationError, setShowUserDeniedGeolocationError] = useState(false)
  const [isCondensed, setIsCondensed] = useState(props.showCondensedView)

  const handleLocationRadioChange = event => {
    var selection = event.target.value
    var locationFilterType = parseInt(selection)

    if (locationFilterType === 1 && !formik.values.coords) {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          position => {
            formik.handleChange({
              target: {
                name: 'locationFilterType',
                value: 1,
              },
            })
            formik.handleChange({
              target: {
                name: 'coords',
                value: position.coords,
              },
            })
          },
          error => {
            if (error.code === error.PERMISSION_DENIED) {
              formik.handleChange({
                target: {
                  name: 'locationFilterType',
                  value: 2,
                },
              })
              setShowUserDeniedGeolocationError(true)
              console.log('User denied location')
            } else {
              formik.handleChange({
                target: {
                  name: 'locationFilterType',
                  value: 2,
                },
              })
              setShowGeolocationError(true)
              console.error('Something went wrong getting the location', error)
            }
          }
        )
      } else {
        formik.handleChange({
          target: {
            name: 'locationFilterType',
            value: 2,
          },
        })
        setShowGeolocationError(true)
      }
    } else {
      formik.handleChange({
        target: {
          name: 'locationFilterType',
          value: locationFilterType,
        },
      })
    }
  }

  const handleEventTypeChange = value => event => {
    if (event.target.checked) {
      formik.handleChange({
        target: {
          name: 'eventTypes',
          value: [...formik.values.eventTypes, value],
        },
      })
    } else {
      if (formik.values.eventTypes.some(eventType => eventType.id === value.id)) {
        const eventTypes = [...formik.values.eventTypes]
        eventTypes.splice(
          eventTypes.findIndex(eventType => eventType.id === value.id),
          1
        )
        formik.handleChange({
          target: {
            name: 'eventTypes',
            value: eventTypes,
          },
        })
      }
    }
  }

  const handleDateChange = (name, date) => {
    formik.handleChange({
      target: {
        name: name,
        value: date,
      },
    })
  }

  const eventListFilterSchema = yup.object().shape({
    distance: yup.number().required('Distance is required'),
    city: yup
      .string()
      .nullable()
      .when('locationFilterType', {
        is: value => value === 2,
        then: yup.string().required('City is required'),
        otherwise: yup.string().nullable(),
      }),
    province: yup
      .string()
      .nullable()
      .when('locationFilterType', {
        is: value => value === 2,
        then: yup.string().required('Province is required'),
        otherwise: yup.string().nullable(),
      }),
    startDate: yup.date().nullable().required('Start date is required'),
    endDate: yup.date().nullable(),
  })

  const formik = useFormik({
    initialValues: { ...props.initialListFilters },
    validationSchema: eventListFilterSchema,
    onSubmit: values => {
      props.onSubmit(values)
    },
  })

  const renderEventTypes = () => {
    return eventTypes.map(eventType => {
      return (
        <Grid item xs={6} key={eventType.id}>
          <FormControlLabel
            checked={formik.values.eventTypes.includes(eventType)}
            control={<Checkbox name='eventType' value={eventType} onChange={handleEventTypeChange(eventType)} />}
            label={eventType.description}
          />
        </Grid>
      )
    })
  }

  return (
    <>
      <form className='list-filter-form'>
        <Grid item xs={12}>
          <Typography className='label' color='inherit' variant='h6'>
            Distance (miles):
          </Typography>
        </Grid>
        <Grid item xs={12} className='distance-slider-container'>
          <Slider
            name='distance'
            onChange={(event, value) =>
              formik.handleChange({
                target: {
                  name: 'distance',
                  value: value,
                },
              })
            }
            marks={radiusOptions}
            value={formik.values.distance}
            step={10}
            valueLabelDisplay='auto'
            min={25}
            max={1000}
          />
        </Grid>
        <Divider />
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Typography className='label' variant='h6'>
              Location:
            </Typography>
          </Grid>
          <Grid container xs={12}>
            <Grid item xs={2}>
              <Radio
                color='primary'
                checked={formik.values.locationFilterType === 1}
                onChange={handleLocationRadioChange}
                value={1}
                name='locationFilterType'
              />
            </Grid>
            <Grid item xs={10} className='my-location-label'>
              <FormLabel value='1' children='Use my location' />
            </Grid>
          </Grid>
          <Grid container spacing={2} xs={12}>
            <Grid item xs={2}>
              <Radio
                color='primary'
                checked={formik.values.locationFilterType === 2}
                onChange={handleLocationRadioChange}
                value={2}
                name='locationFilterType'
              />
            </Grid>
            <Grid item xs={5}>
              <TextField
                id='city'
                name='city'
                placeholder='City'
                value={formik.values.city}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                disabled={formik.values.locationFilterType === 1}
                error={formik.touched.city && !!formik.errors.city}
                helperText={formik.touched.city && formik.errors.city}
              />
            </Grid>
            <Grid item xs={5}>
              <FormControl className='province'>
                <Select
                  id='province'
                  name='province'
                  value={formik.values.province}
                  onChange={formik.handleChange}
                  disabled={formik.values.locationFilterType === 1}
                  error={formik.touched.province && !!formik.errors.province}
                  autoWidth
                >
                  {provinceOptions.map(option => {
                    return (
                      <MenuItem key={option.key} value={option.name}>
                        {option.name}
                      </MenuItem>
                    )
                  })}
                </Select>
                {formik.touched.province && !!formik.errors.province && (
                  <FormHelperText error={!!formik.errors.province}>{formik.errors.province}</FormHelperText>
                )}
              </FormControl>
            </Grid>
          </Grid>
        </Grid>
        <Divider />
        {!isCondensed && (
          <>
            <Grid item xs={12}>
              <Typography className='label' color='inherit' variant='h6'>
                Event Type(s):
              </Typography>
            </Grid>
            <Grid container xs={12} className='event-types'>
              {renderEventTypes()}
            </Grid>
            <Divider />
            <Grid item xs={12}>
              <Typography className='label' variant='h6'>
                Dates:
              </Typography>
            </Grid>
            <Grid container spacing={1}>
              <MuiPickersUtilsProvider utils={MomentUtils}>
                <Grid item xs={5}>
                  <KeyboardDatePicker
                    id={`startDate`}
                    key={`startDate`}
                    name='startDate'
                    value={formik.values.startDate && formik.values.startDate.local()}
                    onChange={date => {
                      if (!date) {
                        handleDateChange('startDate', date)
                      } else if (date.isSame(moment(), 'day')) {
                        handleDateChange('startDate', moment())
                      } else {
                        handleDateChange('startDate', date.startOf('day'))
                      }
                    }}
                    minDate={moment()}
                    error={formik.touched.startDate && !!formik.errors.startDate}
                    helperText={formik.touched.startDate && formik.errors.startDate}
                  />
                </Grid>
                <Grid item xs={2}>
                  <div className='date-filter-to'>to</div>
                </Grid>
                <Grid item xs={5}>
                  <KeyboardDatePicker
                    id={`endDate`}
                    key={`endDate`}
                    name='endDate'
                    value={formik.values.endDate && formik.values.endDate.local()}
                    onChange={date => handleDateChange('endDate', date ? date.endOf('day') : date)}
                    minDate={formik.values.startDate}
                    error={formik.touched.endDate && !!formik.errors.endDate}
                    helperText={formik.touched.endDate && formik.errors.endDate}
                  />
                </Grid>
              </MuiPickersUtilsProvider>
            </Grid>
            <Divider />
          </>
        )}
        <div className='button-container'>
          {props.showCondensedView && isCondensed && (
            <a onClick={() => setIsCondensed(false)}>
              More
              <ArrowDownIcon fontSize='medium' />
            </a>
          )}
          {props.showCondensedView && !isCondensed && (
            <a onClick={() => setIsCondensed(true)}>
              Less
              <ArrowUpIcon fontSize='medium' />
            </a>
          )}
          <Button
            onClick={formik.handleSubmit}
            type='submit'
            variant='contained'
            color='secondary'
            disabled={props.isDisabled}
          >
            Update
          </Button>
        </div>
      </form>
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        key={`UserDeniedGeolocationError`}
        open={showUserDeniedGeolocationError}
        onClose={() => setShowUserDeniedGeolocationError(false)}
        autoHideDuration={5000}
      >
        <Alert elevation={6} variant='filled' severity='error'>
          <span className='snackbar'>
            We don't have permission to use your location. Please update your settings so we can provide you with more
            relevant results.{' '}
            <IconButton
              size='small'
              aria-label='close'
              color='inherit'
              onClick={() => setShowUserDeniedGeolocationError(false)}
            >
              <CloseIcon fontSize='small' />
            </IconButton>
          </span>
        </Alert>
      </Snackbar>{' '}
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        key={`GeolocationError`}
        open={showGeolocationError}
        onClose={() => setShowGeolocationError(false)}
        autoHideDuration={5000}
      >
        <Alert elevation={6} variant='filled' severity='error'>
          <span className='snackbar'>
            Something went wrong trying to get your location.{' '}
            <IconButton size='small' aria-label='close' color='inherit' onClick={() => setShowGeolocationError(false)}>
              <CloseIcon fontSize='small' />
            </IconButton>
          </span>
        </Alert>
      </Snackbar>
    </>
  )
}

export default EventListFilterForm
