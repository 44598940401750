import React from 'react'
import LocationForm from './LocationForm'
import { useMutation } from 'react-fetching-library'

function LocationFormContainer(props) {
  const fetchLocationsAction = description => ({
    method: 'GET',
    endpoint: `/api/locations?description=${description}`
  })
  const fetchLocations = useMutation(fetchLocationsAction, false)

  return <LocationForm {...props} fetchLocations={fetchLocations} />
}

export default LocationFormContainer
