export const eventTypes = [
  {
    id: '1',
    description: 'Autocross',
    colour: 'dark-red',
  },
  {
    id: '2',
    description: 'Cruise/Meet/Show',
    colour: 'red',
  },
  {
    id: '3',
    description: 'Drag Racing',
    colour: 'grey',
  },
  {
    id: '4',
    description: 'Drifting',
    colour: 'dark-grey',
  },
  {
    id: '5',
    description: 'Lapping/Driver Education',
    colour: 'dark-blue',
  },
  {
    id: '7',
    description: 'Rally/Rallycross',
    colour: 'dark-teal',
  },
  {
    id: '9',
    description: 'Road Racing/Time Attack',
    colour: 'green',
  },
  {
    id: '10',
    description: 'Short Track Racing',
    colour: 'yellow',
  },
  {
    id: '11',
    description: 'Other',
    colour: 'orange',
  },
  {
    id: '12',
    description: 'Karting',
    colour: 'amber',
  },
]

/*
blue-grey
deep-orange
blue
teal
dark-green
brown
grey
blue-grey
*/

export const getEventTypeDescription = eventTypeId => {
  var eventType = eventTypes.find(x => x.id === eventTypeId)
  if (eventType) {
    return eventType.description
  }
  return ''
}

export const getEventTypeColour = eventTypeId => {
  var eventType = eventTypes.find(x => x.id === eventTypeId)
  if (eventType) {
    return eventType.colour
  }
  return ''
}
